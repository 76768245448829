import React, { useState } from 'react'
import { Button, ButtonGroup } from '@material-ui/core'
import styled from 'styled-components'
import { LiveStats, FuelRequestTimeSlider, FleetDeliveryTimeSlider } from '../components'

const MobileDiv = styled.div`
  @media only screen and (min-width: 600px) {
    display: none;
  }
`

export const MobileMenu = () => {
  const [ isTimeSliderOpen, setIsTimeSlider ] = useState(false)
  const [ isLiveStatsOpen, setIsLiveStatsOpen ] = useState(false)
  return (
    <MobileDiv>
      <ButtonGroup fullWidth
        size='small'
        color='primary'
      >
        <Button
          onClick={() => setIsTimeSlider(!isTimeSliderOpen)}
        >{isTimeSliderOpen ? 'Close Sliders' : 'Time Sliders'}</Button>
        <Button
          onClick={() => setIsLiveStatsOpen(!isLiveStatsOpen)}
        >{isLiveStatsOpen ? 'Close Live Stats' : 'Live Stats'}</Button>
      </ButtonGroup>
      <div>
        {isTimeSliderOpen && <FuelRequestTimeSlider />}
        {isTimeSliderOpen && <FleetDeliveryTimeSlider />}
        {isLiveStatsOpen && <LiveStats />}
      </div>
    </MobileDiv>
  )
}

import React from 'react'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography
} from '@material-ui/core'
import { FuelRequestTimeSlider, FleetDeliveryTimeSlider } from '../'
import { ExpandMore } from '@material-ui/icons'
import styled from 'styled-components'

const StyledExpansionPanel = styled(ExpansionPanel)`
  && {
    background: #ece6ff;
    /* box-shadow: inset 5px 5px 5px #ccc; */
  }
`

const TimeSliderExpansionPanel = (props) => {
  return (
    <StyledExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMore />}
      >
        <Typography
          variant='overline'
        >
          <strong>Fuel Request and Fleet Delivery Time Sliders</strong>
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <FuelRequestTimeSlider />
        <FleetDeliveryTimeSlider />
      </ExpansionPanelDetails>
    </StyledExpansionPanel>
  )
}

export default TimeSliderExpansionPanel

export const getCookie = name => {
  const cookie = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
  return cookie ? cookie[2] : null
}

export const setCookie = (name, value, days) => {
  let domain = ''

  if (process.env.REACT_APP_ENV === 'staging') {
    domain = 'domain=.boosterio.us'
  }

  if (process.env.REACT_APP_ENV === 'production') {
    domain = 'domain=.boosterfuels.com'
  }

  const date = new Date()
  date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * days)
  document.cookie = name + '=' + value + ';path=/;' + domain + ';expires=' + date.toGMTString() + ';'
}

export const deleteCookie = name => {
  setCookie(name, '', -1)
}

export function setFleetDeliveries (fleetDeliveries = []) {
  return {
    type: 'SET_FLEET_DELIVERIES',
    payload: fleetDeliveries
  }
}

export function setFuelTypeFilter (fuelType) {
  return {
    type: 'FD_SET_FUEL_TYPE_FILTER',
    payload: fuelType
  }
}

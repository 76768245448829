import React from 'react'
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl
} from '@material-ui/core'

const TilesetControl = props => {
  const { tilesetType, handleTilesetChange } = props
  const tilesets = ['noTraffic', 'traffic', 'satellite']
  const tilesetText = ['Normal', 'Traffic', 'Satellite']
  return (
    <FormControl component='fieldset'>
      <RadioGroup
        name='tileset'
        value={tilesetType}
        onChange={(e, v) => handleTilesetChange(v)}
      >
        {tilesets.map((tileset, index) =>
          <FormControlLabel
            key={tileset}
            value={tileset}
            control={<Radio />}
            label={tilesetText[index]}
          />
        )}
      </RadioGroup>
    </FormControl>
  )
}

export default TilesetControl

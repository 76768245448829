import ReactMapboxGl, { RotationControl, Image, MapContext } from 'react-mapbox-gl'
import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import actions from '../actions'
import { isMobile } from '../util/isMobile'
import {
  FleetDeliverySource,
  FuelRequestSource,
  PolygonSources,
  TankerSource,
  FleetRouteSource,
  SituationalAwarenessSource
} from '../components/MapboxSources'
import {
  MapLegend,
  Navbar,
  LayerControl,
  MobileMenu,
  ActiveTankerListContainer,
  LiveStats
} from '../components'

import { SearchContainer } from '../components/Search'

import { Grid } from '@material-ui/core'
import {
  TankerLayer,
  FuelRequestLayer,
  RouteLayer,
  TerminalPolygonLayer,
  FleetAccountPolygonLayer,
  TerritoryPolygonLayer,
  ServiceLocationPolygonLayer,
  FleetDeliveryLayer,
  ServiceProClaimedPinLayer
} from '../components/MapboxLayers'
import styled from 'styled-components'
import SituationalAwarenessLayers from '../components/MapboxLayers/SituationalAwarenessLayers'
import { SituationalAwarenessStatsContainer } from '../components/SituationalAwarenessStats'

const HalfGrid = styled(Grid)`
  height: 50%;
`

const FullGrid = styled(Grid)`
  height: 100%;
`

const RelativeDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const TopLeftCornerDiv = styled.div`
  display: flex;
  flex: 1;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 10;
`

const BottomLeftCornerDiv = styled.div`
  position: absolute;
  left: 0;
  bottom: 10%;
  z-index: 10;
`

const MapViewContainer = styled.div`
  max-height: 100%;
  height: 100%;
  overflow: hidden;
`

const Map = ReactMapboxGl({
  accessToken: 'pk.eyJ1IjoicWltaW5nZmFuZyIsImEiOiJjaXFjeHE5bjYwMmI1ZmpucDIwbDJ2c3BhIn0.1GyI_6KJ7H9dYNVluF6dSg',
  minZoom: 4,
  maxZoom: 25,
  dragRotate: false,
  pitchWithRotate: false
})

const flyToOptions = {
  speed: 2,
  curve: 1,
  zoom: 10
}

class MapView extends Component {
  constructor (props) {
    super(props)
    this.featureRef = React.createRef()
  }

  componentDidMount () {
    this.props.getCustomerState()
    this.checkForDarkMode()
  }

  componentDidUpdate (prevProps) {
    if (this.props.map.tilesetType !== prevProps.map.tilesetType) {
      this.checkForDarkMode()
    }
  }

  checkForDarkMode () {
    if (this.darkModeChecker) {
      clearInterval(this.darkModeChecker)
    }
    this.props.setTileset(this.props.map.tilesetType)
    this.darkModeChecker = setInterval(() => {
      this.props.setTileset(this.props.map.tilesetType)
    }, 600000)
  }

  componentWillUnmount () {
    if (this.darkModeChecker) {
      clearInterval(this.darkModeChecker)
    }
  }

  render () {
    const { center, zoom, tileset } = this.props.map
    return (
      <MapViewContainer>
        <Navbar />
        {isMobile() && <MobileMenu />}
        <Grid
          container
          direction='column'
          style={{ height: '100%' }}
          alignItems='stretch'
        >
          <FullGrid
            container item
            direction='row'
            alignItems='stretch'
            style={{ flexGrow: 1 }}
          >
            {!isMobile() &&
              <FullGrid item xs={2}>
                <ActiveTankerListContainer />
              </FullGrid>}
            <Grid item xs={!isMobile() ? 8 : 12}>
              <RelativeDiv
                className='relative-div'
              >
                <TopLeftCornerDiv>
                  <SearchContainer />
                </TopLeftCornerDiv>
                <Map
                  style={tileset}
                  center={center}
                  zoom={zoom}
                  flyToOptions={flyToOptions}
                  containerStyle={{
                    height: '95%',
                    width: '100%'
                  }}
                >
                  <MapContext.Consumer>
                    {(map) => {
                      const currentStyle = map.getStyle()
                      if (currentStyle.sprite.replace('sprites', 'styles') !== tileset) {
                        map.setStyle(tileset)
                      }
                    }}
                  </MapContext.Consumer>
                  <MapboxImages />
                  <RotationControl />
                  <MapboxSources />
                  <MapboxLayers />
                </Map>
              </RelativeDiv>
            </Grid>
            {!isMobile() &&
              <FullGrid
                item
                xs={2}
              >
                <FullGrid
                  container
                  direction='row'
                  spacing={2}
                >
                  <HalfGrid item xs={12}>
                    <LiveStats />
                  </HalfGrid>
                  <HalfGrid item xs={12}>
                    <SituationalAwarenessStatsContainer />
                  </HalfGrid>
                </FullGrid>
              </FullGrid>}
          </FullGrid>
        </Grid>
      </MapViewContainer>
    )
  }
}

const MapboxImages = () => (
  <>
    <Image id='tanker-pin' url='../../images/icons/tankerMapPin.png' />
    <Image id='fa-pin' url='../../images/icons/fleetAccountPin.png' />
    <Image id='diesel-fa' url='../../images/icons/dieselFleetAccount.png' />
    <Image id='unleaded-fa' url='../../images/icons/unleadedFleetAccount.png' />
    <Image id='min-diesel-fa' url='../../images/icons/minDieselFleetAccount.png' />
    <Image id='min-unleaded-fa' url='../../images/icons/minUnleadedFleetAccount.png' />
    <Image id='opt-icon' url='../../images/icons/optimus.png' />
    <Image id='tanker-top' url='../../images/icons/tankerTop.png' />
    <Image id='opt-tanker-top' url='../../images/icons/optTankerTop.png' />
    <Image id='f250-top' url='../../images/icons/f250Top.png' />
    <Image id='fd-cluster-agg' url='../../images/icons/clusteredDelivery.png' />
    <Image id='trip-icon' url='../../images/icons/tripIcon.png' />
    <Image id='filled' url='../../images/icons/filled.png' />
    <Image id='not-filled' url='../../images/icons/notFilled.png' />
  </>
)

const MapboxSources = () => (
  <>
    <FleetDeliverySource />
    <FuelRequestSource />
    <PolygonSources />
    <TankerSource />
    <FleetRouteSource />
    <SituationalAwarenessSource />
  </>
)

const MapboxLayers = () => (
  <LayerControl>
    <RouteLayer layerName='Route' />
    <TerritoryPolygonLayer layerName='Territory Polygon' />
    <ServiceLocationPolygonLayer layerName='Service Location Polygon' />
    <FleetAccountPolygonLayer layerName='Fleet Account Polygon' />
    <TerminalPolygonLayer layerName='Terminal Polygon' />
    <FuelRequestLayer layerName='Fuel Requests' />
    <FleetDeliveryLayer layerName='Fleet Deliveries' />
    <ServiceProClaimedPinLayer layerName='SP claimed pins' />
    <TankerLayer layerName='Tanker' />
    <SituationalAwarenessLayers layerName='Situational Awareness' />
    <BottomLeftCornerDiv layerName='Legend'>
      <MapLegend />
    </BottomLeftCornerDiv>
  </LayerControl>
)

const enhance = compose(
  connect(
    state => ({
      customer: state.customer,
      timeFilter: state.timeFilter,
      map: state.map
    }),
    {
      getCustomerState: actions.customer.getCustomerState,
      getFullRouteForDriver: actions.map.getFullRouteForDriver,
      clearRoute: actions.map.clearRoute,
      setTileset: actions.map.setTileset
    }
  )
)

export default enhance(MapView)

import React, { Component } from 'react'
import { getAuthToken } from '../util/getAuthToken'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTheme } from '@material-ui/core/styles'
import {
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  LinearProgress,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core'

import actions from '../actions'

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    fullWidth
    label={label}
    error={touched && error}
    {...input}
    {...custom}
  />
)

class Login extends Component {
  render () {
    if (getAuthToken()) {
      this.props.history.go('/')
    }
    const {
      showLoader,
      handleSubmit,
      errors
    } = this.props

    if (!this.props.errors) {
      return (
        <span>loading</span>
      )
    }

    return (
      <div style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <form onSubmit={handleSubmit(({ email, password }) => {
          this.props.login({ email, password })
        })}>
          <Card>
            { showLoader && <LinearProgress color='secondary' /> }
            <CardContent>
              <Typography gutterBottom variant='h6'>
                Log in to Booster
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    name='email'
                    label='Email'
                    component={renderTextField}
                    type='text' />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name='password'
                    label='Password'
                    component={renderTextField}
                    type='password' />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Grid container justify='flex-end'>
                <Grid item>
                  <Button
                    variant='contained'
                    disabled={showLoader}
                    type='submit'
                    color='primary'
                    style={{ marginBottom: '12px', marginRight: '8px' }}>LOGIN
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </form>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={!!errors.errorMessage}
          autoHideDuration={3000}
          onClose={() => this.props.clearError()}
          message={<span>{errors.errorMessage}</span>}
        />
      </div>
    )
  }
}

const enhance = compose(
  connect(
    state => ({
      showLoader: state.spinner.login,
      errors: state.error
    }),
    {
      login: actions.customer.login,
      clearError: actions.error.clearError
    }
  ),
  reduxForm({
    form: 'login'
  }),
  withTheme
)

export default enhance(Login)

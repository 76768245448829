import React, { useEffect, useState } from "react";
import { version } from "../package.json";
import { getAuthToken } from "./util/getAuthToken";
import { BrowserRouter as Router } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import actions from "./actions";
import Spinner from "./components/_shared/Spinner";
import { PrivateRoute, PublicRoute } from "./components/AuthGuard";
import firebase from "./util/firebase";

import Login from "./views/Login";
import MapView from "./views/MapView";

const App = () => {
  const dispatch = useDispatch();
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const loggedIn = getAuthToken();
  const argosToken = useSelector((state) => state.customer.argosToken);

  useEffect(() => {
    dispatch(actions.customer.getCustomerState());
    document.title = `Argos - v${version}`;
  }, []);

  useEffect(() => {
    if (loggedIn) {
      dispatch(actions.customer.generateArgosToken());
    }
  }, [dispatch, loggedIn]);

  useEffect(() => {
    if (argosToken) {
      firebase
        .auth()
        .signInWithCustomToken(argosToken)
        .then(() => {
          setInitiallyLoaded(true);
          dispatch(actions.customer.setFirebaseAuthenticated(true));
        });
    }
  });

  if (loggedIn && !initiallyLoaded) {
    return <Spinner />;
  }

  return (
    <Router>
      <PrivateRoute
        exact
        path="/"
        authenticated={loggedIn}
        component={MapView}
      />
      {process.env.NODE_ENV === "development" && (
        <PublicRoute path="/login" authenticated={loggedIn} component={Login} />
      )}
    </Router>
  );
};

export default App;

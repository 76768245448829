import { green, purple, yellow, red, orange } from '@material-ui/core/colors'

const colors = [
  purple.A700,
  red.A700,
  orange.A700,
  yellow.A700,
  yellow.A700,
  green.A700
]

export default(colors)

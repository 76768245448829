const initialState = {
  allRegions: [],
  selectedRegion: null
}

export default function (state = initialState, action) {
  if (action.error) return state

  switch (action.type) {
    case 'FETCH_REGIONS_FULFILLED':
      return {
        ...state,
        allRegions: action.payload
      } || {}

    case 'SELECT_REGION':
      return {
        ...state,
        selectedRegion: state.allRegions.find(r => r._id === action.payload) || null
      } || {}

    default:
      return state
  }
}

export function setFuelRequests (fuelRequests = []) {
  return {
    type: 'SET_FUEL_REQUESTS',
    payload: fuelRequests
  }
}

export function setFuelTypeFilter (fuelType) {
  return {
    type: 'FR_SET_FUEL_TYPE_FILTER',
    payload: fuelType
  }
}

export function setDriverFilter (driverId) {
  return {
    type: 'FR_SET_DRIVER_FILTER',
    payload: driverId
  }
}

export function clearDriverFilter () {
  return {
    type: 'FR_CLEAR_DRIVER_FILTER'
  }
}

export function setPinFilter (filterType) {
  return {
    type: 'FR_SET_PIN_FILTER',
    payload: filterType
  }
}

import React from 'react'
import styled from 'styled-components'
import { deepPurple } from '@material-ui/core/colors'
import { Grid, Typography } from '@material-ui/core'

const Image = styled.img`
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin-right: 5px;
`

const StyledAnchorTag = styled.a`
  text-decoration: none;
  color: ${deepPurple.A700};
`

const DEFAULT_IMAGE_URL = 'https://images.ctfassets.net/cris4mvxxaxy/2gaDr9S6bu56ygNyo5ZJqC/e96b6b4870372aee70c3dfdec8dd1c2c/favicon.ico'
const BOA_URL = 'https://tools.boosterfuels.com/'

export const TankerPopup = (props) => {
  const { tanker } = props
  const { properties } = tanker
  const { shortId } = properties
  let tanks
  try {
    tanks = JSON.parse(properties.tanks)
  } catch (err) {
    tanks = properties.tanks
  }
  let driver
  let imgURL
  if (properties.driver) {
    try {
      driver = JSON.parse(properties.driver)
    } catch (e) {
      driver = properties.driver
    }
    imgURL = driver.driverProfile.profileImageUrl || DEFAULT_IMAGE_URL
  }
  return (
    <>
      {driver &&
        <Grid
          container
          justify='space-between'
          alignItems='center'
          spacing={2}
        >
          <Grid item>
            <Image src={imgURL} alt='image' />
          </Grid>
          <Grid item>
            <Typography>
              <StyledAnchorTag
                target='_blank'
                rel='noopener noreferrer'
                href={`${BOA_URL}/#/customers/${driver._id}`}
              >{driver.firstName} {driver.lastName}
              </StyledAnchorTag>
            </Typography>
            <span>{driver.telephone}</span>
            <Typography>
              <StyledAnchorTag
                target='_blank'
                rel='noopener noreferrer'
                href={`${BOA_URL}/#/tankers/${properties._id}`}
              >{shortId}
              </StyledAnchorTag>
            </Typography>
          </Grid>
        </Grid>}
      <Grid
        container
        justify='space-between'
        direction='row'
      >
        {renderTanks(tanks)}
      </Grid>
    </>
  )
}

function renderTanks (t) {
  const tanks = t.map(tank => {
    const { _id, currentFuelLevel, currentFuelType, orientation, totalCapacity } = tank
    return (
      <Grid item key={_id}>
        <Grid
          container
          alignItems='center'
          direction='column'
        >
          <Grid item>
            <Typography variant='body1'>{orientation}</Typography>
          </Grid>
          <Grid item>
            <span>{currentFuelType}</span>
          </Grid>
          <Grid item>
            <span>{parseInt(currentFuelLevel, 10)} / {totalCapacity} Gal</span>
          </Grid>
        </Grid>
      </Grid>
    )
  })
  return tanks
}

import * as errorTypes from '../constants/error.js'
import { loginError } from '../actions/error'

const errorCatchMiddleware = store => next => action => {
  if (action.type === errorTypes.AUTH_ERROR) {
    store.dispatch(loginError())
  } else {
    return next(action)
  }
}

export default errorCatchMiddleware

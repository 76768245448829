import { Source } from 'react-mapbox-gl'
import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { isFilteredFuelType } from '../../util/isFilteredFuelType'
import centroid from '@turf/centroid'
import { createFeatureCollection } from '../../util/geojsonUtils'
import actions from '../../actions'
import moment from 'moment-timezone'

import firebase from '../../util/firebase'

class FleetDeliverySource extends Component {
  constructor (props) {
    super(props)
    this.state = {
      unsubscribeFromCurrentFleetDeliverySnapshot: null
    }
  }

  componentDidMount () {
    if (this.props.regions.selectedRegion) {
      this.subscribeToFleetDeliveriesFromFirebase()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.regions.selectedRegion !== prevProps.regions.selectedRegion) {
      if (this.unsubscribeFromCurrentFleetDeliverySnapshot) {
        this.unsubscribeFromCurrentFleetDeliverySnapshot()
      }
      this.subscribeToFleetDeliveriesFromFirebase()
    }
  }

  render () {
    const { route } = this.props.map
    const { minStart, maxStart } = this.props.timeFilter.fleetDelivery
    const { fuelTypeFilter } = this.props.fleetDelivery
    const { timezone } = this.props.regions.selectedRegion || 'America/Los_Angeles'
    const fleetDeliveries = this.props.fleetDelivery.deliveries.map(fd => {
      const fleetAccount = this.props.fleetAccounts[fd.fleetAccountId]
      if (!fleetAccount) {
        return {}
      }
      return {
        location: fleetAccount.navigationLocation || centroid(fleetAccount.location),
        name: fleetAccount.name,
        avgDieselGallonsLast5Deliveries: fleetAccount.avgDieselGallonsLast5Deliveries,
        avgPremiumGallonsLast5Deliveries: fleetAccount.avgPremiumGallonsLast5Deliveries,
        avgRegularGallonsLast5Deliveries: fleetAccount.avgRegularGallonsLast5Deliveries,
        ...fd
      }
    })
    const fleetDeliveriesOnRoute = {}
    if (route.length > 0) {
      route.forEach(r => {
        fleetDeliveriesOnRoute[r.fleetDeliveryId] = r
      })
    }
    const timeFilteredFleetDeliveries = fleetDeliveries
      .filter(doc =>
        doc != null &&
        doc.startTime &&
        doc.endTime &&
        moment(doc.startTime).isSameOrAfter(moment(minStart)) &&
        moment(doc.startTime).isBefore(moment(maxStart)) &&
        isFilteredFuelType(determineFuelTypes(doc), fuelTypeFilter) &&
        ['SCHEDULED', 'CHECKED_IN', 'IN_PROGRESS'].includes(doc.status)
      )
      .map(doc => {
        return {
          ...doc,
          serviceWindow: `${moment.tz(doc.startTime, timezone).format('HH:mm')} - ${moment.tz(doc.endTime, timezone).format('HH:mm')}`
        }
      })
    const routeFilteredDeliveries = timeFilteredFleetDeliveries.filter(d => {
      if (route.length > 0) {
        return fleetDeliveriesOnRoute[d._id]
      } else {
        return true
      }
    })
    const fleetDeliveryGeoJSON = createFeatureCollection(routeFilteredDeliveries)
    return (
      <Source
        id='fleetDeliveries'
        geoJsonSource={{
          type: 'geojson',
          data: fleetDeliveryGeoJSON,
          cluster: true,
          clusterMaxZoom: 25,
          clusterRadius: 60,
          clusterProperties: {
            totalReg: ['+', ['case', HAS_REG, getReg, 0]],
            totalPre: ['+', ['case', HAS_PRE, getPre, 0]],
            totalDsl: ['+', ['case', HAS_DSL, getDsl, 0]],
            dieselCount: ['+', ['case', HAS_DSL, 1, 0]],
            unleadedCount: [
              '+', [
                'case',
                HAS_REG, 1,
                HAS_PRE, 1,
                0
              ]
            ]
          }
        }}
      />
    )
  }

  subscribeToFleetDeliveriesFromFirebase () {
    const { selectedRegion } = this.props.regions
    const { minStart, maxStart } = this.props.timeFilter.fleetDelivery
    const fleetDeliverySnapshotUnsubscriber = firebase.firestore().collection('FleetDelivery')
      .where('startTime', '>=', minStart)
      .where('startTime', '<', maxStart)
      .where('regionId', '==', selectedRegion._id)
      .onSnapshot((data) => {
        this.props.setFleetDeliveries(data.docs
          .map(doc => doc.data())
        )
      })
    this.setState({
      unsubscribeFromCurrentFleetDeliverySnapshot: fleetDeliverySnapshotUnsubscriber
    })
  }

  unsubscribeFromCurrentFleetDeliverySnapshot () {
    const { unsubscribeFromCurrentFleetDeliverySnapshot } = this.state
    if (unsubscribeFromCurrentFleetDeliverySnapshot) {
      unsubscribeFromCurrentFleetDeliverySnapshot()
    }
  }
}

const determineFuelTypes = (fd) => {
  const fuelTypes = []
  if (fd.avgDieselGallonsLast5Deliveries > 0) {
    fuelTypes.push('DIESEL')
  }
  if (fd.avgPremiumGallonsLast5Deliveries > 0) {
    fuelTypes.push('PREMIUM')
  }
  if (fd.avgRegularGallonsLast5Deliveries > 0) {
    fuelTypes.push('REGULAR')
  }
  return fuelTypes
}

const enhance = compose(
  connect(
    state => ({
      fleetDelivery: state.fleetDelivery || [],
      fleetAccounts: state.fleetAccount || [],
      timeFilter: state.timeFilter || {},
      regions: state.regions || {},
      map: state.map
    }),
    {
      setFleetDeliveries: actions.fleetDelivery.setFleetDeliveries
    }
  )
)

const getReg = ['get', 'avgRegularGallonsLast5Deliveries']
const getPre = ['get', 'avgPremiumGallonsLast5Deliveries']
const getDsl = ['get', 'avgDieselGallonsLast5Deliveries']
const HAS_REG = ['>', getReg, 0]
const HAS_PRE = ['>', getPre, 0]
const HAS_DSL = ['>', getDsl, 0]

export default enhance(FleetDeliverySource)

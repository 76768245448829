export const StorageFactory = (getStorage) => {
  let inMemoryStorage = {}

  const isSupported = () => {
    try {
      const testKey = '___gibberish____ifeiisge'
      getStorage().setItem(testKey, testKey)
      getStorage().removeItem(testKey)
      return true
    } catch (e) {
      return false
    }
  }

  const clear = () => {
    if (isSupported()) {
      getStorage().clear()
    } else {
      inMemoryStorage = {}
    }
  }

  const getItem = (name) => {
    if (isSupported()) {
      return getStorage().getItem(name)
    }
    if (inMemoryStorage.name) {
      return inMemoryStorage[name]
    }
    return null
  }

  const key = (idx) => {
    if (isSupported()) {
      return getStorage().key(idx)
    }
    return Object.keys(inMemoryStorage)[idx] || null
  }

  const removeItem = (name) => {
    if (isSupported()) {
      getStorage().removeItem(name)
    } else {
      delete inMemoryStorage[name]
    }
  }

  const setItem = (name, value) => {
    if (isSupported()) {
      getStorage().setItem(name, value)
    } else {
      inMemoryStorage[name] = String(value)
    }
  }

  const length = () => {
    if (isSupported()) {
      return getStorage().length
    }
    return Object.keys(inMemoryStorage).length
  }

  return {
    getItem,
    setItem,
    removeItem,
    clear,
    key,
    get length () {
      return length()
    }
  }
}

export const clearLocalStorage = (keys) => {
  const localStore = StorageFactory(() => window.localStorage)
  keys.forEach(key => {
    localStore.removeItem()
  })
}

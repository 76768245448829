export const ALL = 'ALL_USERS'

export function canSee (feature, customer) {
  if (!customer || !customer.email) {
    return false
  }

  if (!feature ||
      (!feature.whitelist && !feature.blacklist)) {
    return false
  }

  if (feature.whitelist) {
    return feature.whitelist.includes(customer.email)
  }

  return !feature.blacklist.includes(customer.email)
}

export const v0TankerLiveUpdate = {
  blacklist: [
    // 'fang@boosterfuels.com'
  ]
}

/**
 * Users who can see the v0 chat experience
 */
export const v0Chat = {
  whitelist: [
  ]
}

const initialState = {}

export default function (state = initialState, action) {
  if (action.error) return state

  switch (action.type) {
    case 'FETCH_FA_POLYGONS_FULFILLED':
      const fleetAccounts = {}
      action.payload.forEach(fa => {
        fleetAccounts[fa._id] = { ...fa }
      })
      return fleetAccounts || {}

    default:
      return state
  }
}

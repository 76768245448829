import React, { Fragment, useState, useEffect } from 'react'
import { MapContext, Popup } from 'react-mapbox-gl'
import propTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'

const StyledGridItem = styled(Grid)`
  border: 1px solid darkgrey;
  background: rgba(0, 0, 0, 0.1);
`

export const FleetDeliveryClusterPopup = (props) => {
  const { clusterId, coordinates } = props
  const [clusterChildren, setClusterChildren] = useState(null)
  const [isPopupOpen, setIsPopupOpen] = useState(true)

  useEffect(() => {
    setClusterChildren(null)
    setIsPopupOpen(true)
  }, [clusterId, coordinates])

  return (
    <>
      <MapContext.Consumer>
        {(map) => {
          if (clusterChildren) {
            return
          }
          const clusterSource = map.getSource('fleetDeliveries')
          promisifiedGetClusterChildren(clusterSource, clusterId)
            .then(children => {
              resolveClustersToProperties(children, clusterSource)
                .then((res) => {
                  setClusterChildren(res)
                })
            })
        }}
      </MapContext.Consumer>
      {isPopupOpen && clusterChildren &&
        <Popup
          coordinates={coordinates}
          onClick={() => setIsPopupOpen(false)}
        >
          <Grid
            container
            direction='column'
          >
            {clusterChildren.map(child => {
              const { properties } = child
              return (
                <Fragment key={properties._id}>
                  <StyledGridItem item>
                    <Grid
                      container
                      justify='space-between'
                      spacing={1}
                    >
                      <Grid item xs={4}>
                        <Typography variant='caption'>{properties.name}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant='caption'><strong>{properties.serviceWindow}</strong></Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant='caption'>{properties.status}</Typography>
                      </Grid>
                    </Grid>
                  </StyledGridItem>
                </Fragment>
              )
            })}
          </Grid>
        </Popup>}
    </>
  )
}

const promisifiedGetClusterChildren = (clusterSource, clusterId) => {
  return new Promise((resolve, reject) => {
    clusterSource.getClusterChildren(clusterId, (err, children) => {
      if (err) {
        return reject(err)
      }
      resolve(children)
    })
  })
}

const resolveClustersToProperties = async (children, clusterSource) => {
  const flattenedChildren = []
  for (const child of children) {
    const clusterId = child.properties.cluster_id
    if (clusterId) {
      const newChildren = await promisifiedGetClusterChildren(clusterSource, clusterId)
      const resolvedClusters = await resolveClustersToProperties(newChildren, clusterSource)
      flattenedChildren.push(...resolvedClusters)
    } else {
      flattenedChildren.push(child)
    }
  }
  return flattenedChildren
}

FleetDeliveryClusterPopup.propTypes = {
  clusterId: propTypes.number.isRequired,
  coordinates: propTypes.array.isRequired
}

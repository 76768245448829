import React, { useState } from 'react'
import { Popup, Layer } from 'react-mapbox-gl'
import { blue, green, purple, yellow, red, orange } from '@material-ui/core/colors'
import { FuelRequestPopup } from '../MapboxPopups'
import { FuelRequestCluster } from '../MapboxLayers'

export const FuelRequestLayer = (props) => {
  const [ selectedFuelRequest, selectFuelRequestForPopup ] = useState(null)

  function selectFuelRequest (fuelRequest) {
    selectFuelRequestForPopup(fuelRequest)
  }

  function deselectFuelRequest () {
    selectFuelRequestForPopup(null)
  }

  return (
    <>
      <FuelRequestCluster />
      <Layer
        id='unclustered-fr'
        type='circle'
        sourceId='fuelRequests'
        filter={['!', ['has', 'point_count']]}
        paint={{
          'circle-color': [
            'case',
            ['==', ['get', 'status'], 'PAUSED'], blue.A700,
            [
              'match',
              ['get', 'priorityGroup'],
              'COMPLETE_WITHIN_15MIN', purple.A700,
              'COMPLETE_WITHIN_30MIN', red.A700,
              'COMPLETE_WITHIN_1HR', orange.A700,
              'COMPLETE_WITHIN_2HR', yellow.A700,
              'COMPLETE_BY_12PM', yellow.A700,
              'COMPLETE_BY_END_OF_DAY', green.A700,
              green.A700
            ]
          ],
          'circle-radius': 10,
          'circle-stroke-width': [
            'case',
            ['==', ['get', 'status'], 'PAUSED'], 10,
            ['==', ['get', 'fuelType'], 'PREMIUM'], 10,
            1
          ],
          'circle-stroke-color': [
            'case',
            ['==', ['get', 'status'], 'PAUSED'], 'red',
            ['==', ['get', 'fuelType'], 'PREMIUM'], 'yellow',
            '#fff'
          ],
          'circle-opacity': 0.85
        }}
        onClick={e => selectFuelRequest(e.features[0])}
      />
      <Layer
        id='fr-label'
        type='symbol'
        sourceId='fuelRequests'
        filter={['!', ['has', 'point_count']]}
        layout={{
          'text-field': ['number-format', ['get', 'estimatedFuelAmount'], { 'min-fraction-digits': 1, 'max-fraction-digits': 1 }],
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-size': 10
        }}
        paint={{
          'text-color': 'black'
        }}
      />
      {selectedFuelRequest &&
        <Popup
          onClick={deselectFuelRequest}
          coordinates={selectedFuelRequest.geometry.coordinates}
        >
          <FuelRequestPopup fuelRequest={selectedFuelRequest} />
        </Popup>
      }
    </>
  )
}

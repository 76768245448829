import React from "react";
import propTypes from "prop-types";
import {
  Avatar,
  Typography,
  Grid,
  Divider,
  MenuItem,
  ListItemIcon,
} from "@material-ui/core";
import { aggregateFuel, percentToColor } from "../../util/fuelUtils";
import moment from "moment-timezone";
const DEFAULT_IMAGE_URL =
  "https://images.ctfassets.net/cris4mvxxaxy/2gaDr9S6bu56ygNyo5ZJqC/e96b6b4870372aee70c3dfdec8dd1c2c/favicon.ico";

const ActiveTankerListItem = (props) => {
  const { tanker, selectedTankerId, onTankerSelect } = props;
  const { tanks } = tanker;
  const { firstName, lastName, driverProfile } = tanker.driver;
  const fuelValues = aggregateFuel(tanks);

  const handleClick = () => {
    if (selectedTankerId === tanker._id) {
      onTankerSelect(null);
    } else {
      onTankerSelect(tanker);
    }
  };
  return (
    <>
      <MenuItem
        button
        selected={tanker._id === selectedTankerId}
        onClick={handleClick}
      >
        <ListItemIcon>
          <Avatar
            alt={`${firstName} ${lastName}`}
            src={driverProfile.profileImageUrl || DEFAULT_IMAGE_URL}
          />
        </ListItemIcon>
        <Grid container direction="column" item>
          <Grid container spacing={2}>
            <Grid item>
              <Typography>
                <strong>
                  {firstName} {lastName}
                </strong>
              </Typography>
              <Typography>{tanker.shortId}</Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={2}>
            {fuelValues.map((fuelValue) => {
              return (
                <Grid item>
                  <small
                    style={{
                      color: percentToColor(
                        fuelValue.total / fuelValue.capacity
                      ),
                    }}
                  >
                    [{fuelValue.fuelType}]: {fuelValue.total}/
                    {fuelValue.capacity}
                  </small>
                </Grid>
              );
            })}
          </Grid>
          <Grid container item>
            <Grid item>
              <Typography variant="caption">
                Location updated{" "}
                <em>{getLocationUpdateText(tanker.locationUpdatedAt)}</em>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </MenuItem>
      <Divider />
    </>
  );
};

const getLocationUpdateText = (locationUpdatedAt) => {
  const locationMoment = moment(locationUpdatedAt);
  const seconds = moment().diff(locationMoment, "seconds");
  if (seconds < 60) {
    return `${seconds} second(s) ago`;
  }
  const minutes = moment().diff(locationMoment, "minutes");
  if (minutes < 60) {
    return `${minutes} minute(s) ago`;
  }
  const hours = moment().diff(locationMoment, "hours");
  return `${hours} hour(s) ago`;
};

ActiveTankerListItem.propTypes = {
  tanker: propTypes.object.isRequired,
  selectedTankerId: propTypes.string,
  onTankerSelect: propTypes.func.isRequired,
};

export default ActiveTankerListItem;

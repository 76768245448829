import * as customer from './customer'
import * as fuelRequest from './fuelRequest'
import * as serviceLocation from './serviceLocation'
import * as map from './map'
import * as drivers from './drivers'
import * as fleetDelivery from './fleetDelivery'
import * as fleetAccount from './fleetAccount'
import * as terminal from './terminal'
import * as error from './error'
import * as territory from './territory'
import * as timeFilter from './timeFilter'
import * as tank from './tank'
import * as regions from './regions'
import * as liveStats from './liveStats'
import * as situationalAwareness from './situationalAwareness'

export default {
  map,
  customer,
  drivers,
  fuelRequest,
  serviceLocation,
  fleetDelivery,
  fleetAccount,
  terminal,
  territory,
  timeFilter,
  regions,
  tank,
  liveStats,
  situationalAwareness,
  error
}

import React, { useEffect } from 'react'
import { Source } from 'react-mapbox-gl'
import { useDispatch, useSelector } from 'react-redux'
import { createFeatureCollection } from '../../util/geojsonUtils'
import actions from '../../actions'
import firebase from '../../util/firebase'
import moment from 'moment-timezone'

// Use this to filter payload because sometimes there are in progress deliveries that are old
const lowerDateRange = moment().subtract(1, 'day')

const SituationalAwarenessSource = (props) => {
  const dispatch = useDispatch()
  const tankerId = useSelector(state => state.situationalAwareness.selectedTankerId)
  const regionId = useSelector(state => state.regions.selectedRegion._id)
  const trip = useSelector(state => state.situationalAwareness.currentTrip)
  const fleetDelivery = useSelector(state => state.situationalAwareness.currentFleetDelivery)
  // When a new tankerId is selected for situational awareness, subscribe to the trip
  // and fleet delivery corresponding to it.
  useEffect(() => {
    if (!regionId || !tankerId) {
      return
    }
    const fleetDeliverySnapshotUnsubscriber = firebase.firestore().collection('FleetDelivery')
      .where('regionId', '==', regionId)
      .where('tankerId', '==', tankerId)
      .where('status', '==', 'IN_PROGRESS')
      .onSnapshot((data) => {
        dispatch(actions.situationalAwareness.setCurrentFleetDelivery(data.docs.map(doc => doc.data()).filter(d => moment(d.startTime.seconds, 'X').isSameOrAfter(lowerDateRange))[0]))
      })
    const tripSnapshotUnsubscriber = firebase.firestore().collection('Trip')
      .where('tankerId', '==', tankerId)
      .onSnapshot((data) => {
        dispatch(actions.situationalAwareness.setCurrentTrip(data.docs.map(doc => doc.data())[0]))
      })
    return function cleanup () {
      fleetDeliverySnapshotUnsubscriber()
      tripSnapshotUnsubscriber()
      dispatch(actions.situationalAwareness.setCurrentFleetDelivery(null))
      dispatch(actions.situationalAwareness.setCurrentTrip(null))
    }
  }, [tankerId, regionId, dispatch])

  const renderTripSource = (trip) => {
    if (trip && trip.locationHistory) {
      return (
        <Source
          id='situational-trip'
          geoJsonSource={{
            type: 'geojson',
            data: trip.locationHistory
          }}
        />
      )
    }
    return (
      <Source
        id='situational-trip'
        geoJsonSource={{
          type: 'geojson',
          data: emptyFeatureCollection
        }}
      />
    )
  }

  const renderFleetDeliverySource = (fleetDelivery) => {
    if (fleetDelivery && fleetDelivery.deliveryDetails) {
      return (
        <Source
          id='situational-delivery-details'
          geoJsonSource={{
            type: 'geojson',
            data: createFeatureCollection(fleetDelivery.deliveryDetails)
          }}
        />
      )
    }
    return (
      <Source
        id='situational-delivery-details'
        geoJsonSource={{
          type: 'geojson',
          data: emptyFeatureCollection
        }}
      />
    )
  }

  return (
    <>
      {renderFleetDeliverySource(fleetDelivery)}
      {renderTripSource(trip)}
    </>
  )
}

const emptyFeatureCollection = {
  type: 'FeatureCollection',
  features: []
}

export default SituationalAwarenessSource

import React, { useState, useEffect } from 'react'
import { Layer, Feature } from 'react-mapbox-gl'

export const HoverOverTextLayer = (props) => {
  const [ labelIsShown, setLabelIsShown ] = useState(true)
  const { coordinates, properties } = props

  useEffect(() => {
    setLabelIsShown(true)
  }, [props.properties])

  return (
    <Layer
      class='hover-over'
      type='symbol'
      layout={{
        'text-allow-overlap': false,
        'text-field': ['get', 'name'],
        'text-variable-anchor': ['top', 'bottom']
      }}
    >
      {
        properties && labelIsShown && <Feature coordinates={coordinates}
          properties={properties}
          onMouseLeave={e => setLabelIsShown(false)}
        />
      }
    </Layer>
  )
}

import React, { Component, Fragment } from 'react'
import { FleetDeliveryTimeSlider } from '..'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'
import actions from '../../actions'
import { RvHookup } from '@material-ui/icons'
import {
  Popover,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from '@material-ui/core'

const FormContainer = styled.div`
  padding: 10%;
`

class FleetDeliveryControl extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isMenuOpen: false,
      menuAnchorEl: null
    }
  }

  handleFuelTypeSelection (event) {
    this.props.setFuelTypeFilter(event.target.value)
  }

  handleMenuOpen (e) {
    this.setState({
      menuAnchorEl: e.currentTarget,
      isMenuOpen: true
    })
  }

  handleMenuClose () {
    this.setState({
      menuAnchorEl: null,
      isMenuOpen: false
    })
  }

  renderFuelTypeItems () {
    const fuelTypes = ['ALL', 'REGULAR', 'PREMIUM', 'DIESEL']
    const { fuelTypeFilter } = this.props
    return (
      <FormContainer>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>FleetDelivery fuel type</FormLabel>
          <RadioGroup name='fd-fueltype'
            value={fuelTypeFilter}
            onChange={(e) => this.handleFuelTypeSelection(e)}
          >
            {fuelTypes.map((ft) =>
              <FormControlLabel value={ft} control={<Radio />} label={ft.toLowerCase()} key={ft} />
            )}
          </RadioGroup>
        </FormControl>
      </FormContainer>
    )
  }

  renderPopover () {
    const { menuAnchorEl, isMenuOpen } = this.state
    return (
      <Popover
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={isMenuOpen}
        onClose={() => this.handleMenuClose()}
      >
        <FleetDeliveryTimeSlider />
        {this.renderFuelTypeItems()}
      </Popover>
    )
  }

  renderButton () {
    return (
      <Button
        color='secondary'
        variant='outlined'
        onClick={(e) => this.handleMenuOpen(e)}
      >
        <RvHookup />
      </Button>
    )
  }

  render () {
    return (
      <Fragment>
        {this.renderButton()}
        {this.renderPopover()}
      </Fragment>
    )
  }
}

const enhance = compose(
  connect(
    state => ({
      customer: state.customer,
      fuelTypeFilter: state.fleetDelivery.fuelTypeFilter || []
    }),
    {
      setFuelTypeFilter: actions.fleetDelivery.setFuelTypeFilter
    }
  )
)

export default enhance(FleetDeliveryControl)

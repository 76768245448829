import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography } from '@material-ui/core'
import { ActiveTankerList } from '.'
import actions from '../../actions'

const ActiveTankerListContainer = (props) => {
  const dispatch = useDispatch()
  const tankers = useSelector(state => state.tankers.tankers)
  const drivers = useSelector(state => state.drivers)
  const tanks = useSelector(state => state.tank)

  const selectTankerForSituationalAwareness = (tanker) => {
    if (tanker == null) {
      dispatch(actions.situationalAwareness.setCurrentTankerId(null))
      dispatch(actions.map.setMapZoom(10))
    } else {
      dispatch(actions.situationalAwareness.setCurrentTankerId(tanker._id))
      dispatch(actions.map.setMapCenter(tanker.lastLocation))
      dispatch(actions.map.setMapZoom(20))
    }
  }

  const selectedTankerId = useSelector(state => state.situationalAwareness.selectedTankerId)
  if (tankers.length === 0 || Object.keys(drivers).length === 0 || tanks.length === 0) {
    return (
      <>
        <Typography align='center'>
          No active tankers
        </Typography>
      </>
    )
  }

  const tankersWithTanks = tankers.map(tanker => {
    return {
      ...tanker,
      tanks: tanks.filter(t => t.tankerId === tanker._id),
      driver: drivers[tanker.currentDriverId]
    }
  })

  return (
    <ActiveTankerList
      tankers={tankersWithTanks}
      selectedTankerId={selectedTankerId}
      onTankerSelect={selectTankerForSituationalAwareness}
    />
  )
}

export default ActiveTankerListContainer

import * as firebase from 'firebase'

var config = {
  apiKey: 'AIzaSyBcfXXLDxK9hDTauIXkWtSM1iuNfJQqPkg',
  authDomain: 'argos-d468a.firebaseapp.com',
  databaseURL: 'https://argos-d468a.firebaseio.com',
  projectId: 'argos-d468a',
  storageBucket: 'argos-d468a.appspot.com',
  messagingSenderId: '967423765514',
  appId: '1:967423765514:web:cf68fdd973b82b441b83f3',
  measurementId: 'G-ZSF6ZEE3CF'
}

firebase.initializeApp(config)

firebase.auth().onAuthStateChanged((user) => {
  console.log(user)
})

export default firebase

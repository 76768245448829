import React from 'react'

export const DonutSegment = (props) => {
  let { start, end, r, r0, color } = props
  if (end - start === 1) end -= 0.00001
  const a0 = 2 * Math.PI * (start - 0.25)
  const a1 = 2 * Math.PI * (end - 0.25)
  const x0 = Math.cos(a0)
  const y0 = Math.sin(a0)
  const x1 = Math.cos(a1)
  const y1 = Math.sin(a1)
  const largeArc = end - start > 0.5 ? 1 : 0

  const Segment = (
    <path
      d={
        `M ${r + r0 * x0} ${r + r0 * y0} ` +
        `L ${r + r * x0} ${r + r * y0} ` +
        `A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} ` +
        `L ${r + r0 * x1} ${r + r0 * y1} ` +
        `A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0}`
      }
      fill={color}
    />
  )
  return Segment
}

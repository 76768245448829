export const aggregateFuel = (tanks) => {
  const fuelValues = {};
  tanks.reduce((acc, tank) => {
    const { currentFuelType } = tank;
    if (!acc[currentFuelType]) {
      acc[currentFuelType] = { total: 0, capacity: 0 };
    }

    acc[currentFuelType].total += Math.round(tank.currentFuelLevel);
    acc[currentFuelType].capacity += tank.totalCapacity;
    return acc;
  }, fuelValues);

  const res = [];
  for (const fuelType in fuelValues) {
    res.push({
      ...fuelValues[fuelType],
      fuelType: shortenFuelType(fuelType),
    });
  }
  return res;
};

export const percentToColor = (percent) => {
  percent = Math.min(percent * 100, 100);
  let r = 0;
  let g = 0;
  const b = 0;
  if (percent < 50) {
    r = 255;
    g = Math.round(5.1 * percent);
  } else {
    g = 255;
    r = Math.round(510 - 5.1 * percent);
  }
  const hex = r * 0x10000 + g * 0x100 + b * 0x1;
  return `#${("000000" + hex.toString(16)).slice(-6)}`;
};

const shortenFuelType = (fuelType) => {
  switch (fuelType) {
    case "DIESEL":
      return "DSL";
    case "REGULAR":
      return "REG";
    case "PREMIUM":
      return "PREM";
    default:
      return fuelType;
  }
};

import moment from 'moment-timezone'

const initialState = {
  deliveries: [],
  fuelTypeFilter: 'ALL'
}

export default function (state = initialState, action) {
  if (action.error) return state

  switch (action.type) {
    case 'SET_FLEET_DELIVERIES':
      const deliveries = action.payload.map(fd => {
        // Since firebase timestamps return unix time, convert them
        // to date
        return {
          ...fd,
          startTime: moment(fd.startTime.seconds, 'X').toDate(),
          endTime: moment(fd.endTime.seconds, 'X').toDate()
        }
      }) || []
      return {
        ...state,
        deliveries
      }

    case 'FD_SET_FUEL_TYPE_FILTER':
      return {
        ...state,
        fuelTypeFilter: action.payload
      }

    default:
      return state
  }
}

const initialState = {}

export default function (state = initialState, action) {
  if (action.error) return state

  switch (action.type) {
    case 'FETCH_LIVE_STATS_FULFILLED':
      return action.payload

    default:
      return state
  }
}

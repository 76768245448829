import React, { useState } from 'react'
import { blue, green, purple, yellow, red, orange } from '@material-ui/core/colors'
import { Close } from '@material-ui/icons'
import { Button } from '@material-ui/core'
import styled from 'styled-components'

const Circle = styled.div`
  height: 20px;
  width: 20px;
  margin: 5px 15px 5px 15px;
  border-radius: 50%;
  border: 1px solid lightgrey;
`

const Rectangle = styled.div`
  height: 10px;
  width: 20px;
  margin: 5px 15px 5px 15px;
  border: 1px solid lightgrey;
`

const LegendBox = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid lightgrey;
  border-radius: 2px;
  margin-left: 5%;
  font-size: 1rem;
`

const LegendRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
`

const LegendHeader = styled.div`
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 10px;
`

export const MapLegend = (props) => {
  const [ isOpen, setLegend ] = useState(true)

  function toggleLegend (bool) {
    setLegend(bool)
  }
  const RedCircle = createCircle(red.A700)
  const PurpleCircle = createCircle(purple.A700)
  const OrangeCircle = createCircle(orange.A700)
  const YellowCircle = createCircle(yellow.A700)
  const GreenCircle = createCircle(green.A700)
  const PausedCircle = createCircle(blue.A700, red.A700)
  const GreenRectangle = createRectangle(green.A700)
  const PurpleRectangle = createRectangle(purple.A700)
  const PremiumCircle = createCircle('rgba(0,0,0,0)', yellow.A700)
  return (
    <div>
      {!isOpen &&
        <LegendBox>
          <Button
            color='primary'
            size='small'
            variant='contained'
            onClick={() => toggleLegend(true)}
          >
            Legend
          </Button>
        </LegendBox>
      }

      {isOpen &&
        <LegendBox>
          <LegendHeader>
            Legend
            <Button
              onClick={() => toggleLegend(false)}
            >
              <Close />
            </Button>
          </LegendHeader>
          <LegendRow>
            <PurpleCircle />
            <small>Complete within 15 min</small>
          </LegendRow>
          <LegendRow>
            <RedCircle />
            <small>Complete within 30 min</small>
          </LegendRow>
          <LegendRow>
            <OrangeCircle />
            <small>Complete within 60 min</small>
          </LegendRow>
          <LegendRow>
            <YellowCircle />
            <small>Complete within 120 min or by 12pm</small>
          </LegendRow>
          <LegendRow>
            <GreenCircle />
            <small>Complete by EOD</small>
          </LegendRow>
          <LegendRow>
            <PausedCircle />
            <small>Paused Pin</small>
          </LegendRow>
          <LegendRow>
            <PremiumCircle />
            <small>Premium Pin</small>
          </LegendRow>
          <LegendRow>
            <GreenRectangle />
            <small>Diesel Fleet Deliveries</small>
          </LegendRow>
          <LegendRow>
            <PurpleRectangle />
            <small>Unleaded Fleet Deliveries</small>
          </LegendRow>
        </LegendBox>
      }
    </div>
  )
}

function createCircle (color, outlineColor) {
  if (outlineColor) {
    return styled(Circle)`
      background: ${color};
      border-color: ${outlineColor};
      border-width: 5px;
      width: 15px;
      height: 15px;
    `
  }
  return styled(Circle)`
    background: ${color};
  `
}

function createRectangle (color) {
  return styled(Rectangle)`
    background: ${color};
  `
}

import { Source } from 'react-mapbox-gl'
import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { get } from 'lodash'
import actions from '../../actions'

class FleetRouteSource extends Component {
  render () {
    const coordinates = this.props.directions.map(el => [el[1], el[0]])
    const lineString = {
      'type': 'Feature',
      'geometry': {
        'type': 'LineString',
        coordinates
      }
    }

    return (
      <Source id='fleetRoutes'
        geoJsonSource={{
          type: 'geojson',
          data: lineString
        }}
      />
    )
  }
}

const enhance = compose(
  connect(
    state => ({
      directions: get(state, 'map.directions', [])
    }),
    {
      getRouteForDriver: actions.map.getFullRouteForDriver
    }
  )
)

export default enhance(FleetRouteSource)

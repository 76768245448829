import React, { useState } from 'react'
import { Layer } from 'react-mapbox-gl'
import { FleetDeliveryCluster } from '.'

export const FleetDeliveryLayer = (props) => {
  const [isMinimized, setIsMinimized] = useState(true)
  return (
    <>
      <FleetDeliveryCluster />
      <Layer
        id='unclustered-fd'
        type='symbol'
        sourceId='fleetDeliveries'
        filter={['!', ['has', 'point_count']]}
        onClick={() => setIsMinimized(!isMinimized)}
        layout={{
          'icon-image': isMinimized ? minimizedIconExpression : enlargedIconExpression,
          'icon-size': 0.60,
          'icon-ignore-placement': true,
          'text-ignore-placement': true,
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
          'text-anchor': 'top',
          'text-field': isMinimized ? minimizedTextField : enlargedTextField,
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-size': 8
        }}
        paint={{
          'text-color': 'black',
          'icon-opacity': 0.6,
          'text-translate': isMinimized ? minimizedIconTranslate : enlargedIconTranslate
        }}
      />
    </>
  )
}

const HAS_REG = ['>', ['get', 'avgRegularGallonsLast5Deliveries'], 0]
const HAS_PRE = ['>', ['get', 'avgPremiumGallonsLast5Deliveries'], 0]
const HAS_DSL = ['>', ['get', 'avgDieselGallonsLast5Deliveries'], 0]
const EMPTY_STRING = ''
const minimizedIconExpression = [
  'case',
  ['>', ['get', 'avgDieselGallonsLast5Deliveries'], 0], 'min-diesel-fa',
  ['>', ['get', 'avgRegularGallonsLast5Deliveries'], 0], 'min-unleaded-fa',
  'min-unleaded-fa'
]
const enlargedIconExpression = [
  'case',
  ['>', ['get', 'avgDieselGallonsLast5Deliveries'], 0], 'diesel-fa',
  ['>', ['get', 'avgRegularGallonsLast5Deliveries'], 0], 'unleaded-fa',
  'unleaded-fa'
]
const minimizedTextField = ['format',
  ['concat', ['get', 'serviceWindow'], '\n'], {},
  ['concat', ['get', 'name'], '\n'], {}
]

const enlargedTextField = [
  ...minimizedTextField,
  ['concat', ['get', 'status'], '\n'], {},
  [
    'concat',
    [
      'case',
      HAS_REG, ['concat', 'REG: ', ['get', 'avgRegularGallonsLast5Deliveries'], '\n'],
      EMPTY_STRING
    ],
    [
      'case',
      HAS_PRE, ['concat', 'PRE: ', ['get', 'avgPremiumGallonsLast5Deliveries'], '\n'],
      EMPTY_STRING
    ],
    [
      'case',
      HAS_DSL, ['concat', 'DSL: ', ['get', 'avgDieselGallonsLast5Deliveries'], '\n'],
      EMPTY_STRING
    ]
  ], {}
]

const minimizedIconTranslate = [0, -14]
const enlargedIconTranslate = [0, -24]

import React from 'react'
import { Layer } from 'react-mapbox-gl'

export const RouteLayer = () => {
  return (
    <div>
      <Layer
        id='route-layer'
        type='line'
        sourceId='fleetRoutes'
        layout={{
          'line-join': 'round',
          'line-cap': 'round'
        }}
        paint={{
          'line-color': 'red',
          'line-width': 5
        }}
        onClick={e => {}}
      />
    </div>
  )
}

const initialState = {
  route: [],
  directions: [],
  center: [-121.912234, 37.391965],
  zoom: [8],
  tilesetType: 'noTraffic',
  tileset: 'mapbox://styles/mapbox/light-v10'
}

export default function (state = initialState, action) {
  if (action.error) return state

  switch (action.type) {
    case 'SET_ROUTE_AND_DIRECTIONS':
      if (action.payload == null) {
        return state
      }
      return Object.assign({}, state, {
        route: action.payload.route || [],
        directions: action.payload.directions || []
      })

    case 'CLEAR_ROUTE':
      return {
        ...state,
        route: [],
        directions: []
      }

    case 'SET_MAP_CENTER':
      return {
        ...state,
        center: action.payload
      }

    case 'SET_MAP_ZOOM':
      return {
        ...state,
        zoom: action.payload || [8]
      }

    case 'SET_TILESET':
      return {
        ...state,
        tileset: action.payload.tileset,
        tilesetType: action.payload.tilesetType
      }

    default:
      return state
  }
}

export function setCurrentFleetDelivery (fleetDelivery) {
  return {
    type: 'SET_SITUATIONAL_AWARENESS_DELIVERY',
    payload: fleetDelivery
  }
}

export function setCurrentTrip (trip) {
  return {
    type: 'SET_SITUATIONAL_AWARENESS_TRIP',
    payload: trip
  }
}

export function setCurrentTankerId (tankerId) {
  return {
    type: 'SET_SITUATIONAL_AWARENESS_TANKER_ID',
    payload: tankerId
  }
}

import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Table, TableBody, TableCell, TableRow, Typography, Grid, Toolbar } from '@material-ui/core'
import moment from 'moment-timezone'
import { round } from 'lodash'
import styled from 'styled-components'

import actions from '../actions'

const StyledToolbar = styled(Toolbar)`
  background-color: #ece6ff;
  justify-content: center;
`
const StyledTable = styled(Table)`
  margin-top: 5%;
  margin-bottom: 5%;
`

class LiveStats extends Component {
  componentDidMount () {
    if (this.props.regions.selectedRegion) {
      this.pollLiveStats()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.regions.selectedRegion !== prevProps.regions.selectedRegion) {
      this.pollLiveStats()
    }
  }

  componentWillUnmount () {
    if (this.liveStatsPoller) {
      clearInterval(this.pollLiveStats)
    }
  }

  pollLiveStats () {
    if (this.liveStatsPoller) {
      clearInterval(this.liveStatsPoller)
    }
    this.getLiveStats()
    this.liveStatsPoller = setInterval(() => this.getLiveStats(), 60000)
  }

  getLiveStats () {
    const { selectedRegion } = this.props.regions
    this.props.fetchLiveStats(selectedRegion._id)
  }

  render () {
    const {
      activeFuelRequestsCount,
      activeTrucksCount,
      completeFuelRequestsCount,
      hoursToCompletion,
      pausedFuelRequestsCount,
      rate,
      remainingRequestsPerTruck,
      requestsPerTruckLastHalfHour,
      total,
      updatedAt
    } = this.props.liveStats
    const { selectedRegion } = this.props.regions
    return (
      <>
        <StyledToolbar>
          <Grid
            container
            justify='center'
            alignItems='center'
            spacing={2}
          >
            <Grid item>
              <Typography
                variant='h6'
                align='center'
                color='textPrimary'
              >
                <strong>OPERATION STATS</strong>
              </Typography>
            </Grid>
            <Grid item>
              {selectedRegion &&
                <Typography variant='caption' color='textPrimary'>
                  <div><small>Last Updated</small></div>
                  <div>{updatedAt ? moment.tz(updatedAt, selectedRegion.timezone).format('hh:mm a') : ''}</div>
                </Typography>}
            </Grid>
          </Grid>
        </StyledToolbar>
        <StyledTable size='small'>
          <TableBody>
            <TableRow>
              <TableCell align='left'>Total Orders</TableCell>
              <TableCell align='right'>{total || 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left'>Active</TableCell>
              <TableCell align='right'>{activeFuelRequestsCount || 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left'>Paused</TableCell>
              <TableCell align='right'>{pausedFuelRequestsCount || 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left'>Complete</TableCell>
              <TableCell align='right'>{completeFuelRequestsCount || 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left'>Rate (deliveries last 30min) </TableCell>
              <TableCell align='right'>{rate || ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left'>Hours to completion</TableCell>
              <TableCell align='right'>{round(hoursToCompletion, 1) || 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left'>Active trucks </TableCell>
              <TableCell align='right'>{activeTrucksCount || 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left'>Requests per truck (last 30min)</TableCell>
              <TableCell align='right'>{round(requestsPerTruckLastHalfHour, 1) || 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left'>Remaing requests per truck</TableCell>
              <TableCell align='right'>{round(remainingRequestsPerTruck, 1) || 0}</TableCell>
            </TableRow>
          </TableBody>
        </StyledTable>
      </>
    )
  }
}

const enhance = compose(
  connect(
    state => ({
      regions: state.regions || {},
      liveStats: state.liveStats || {}
    }),
    {
      setTimeFilter: actions.timeFilter.setTimeFilter,
      fetchLiveStats: actions.liveStats.fetchLiveStats
    }
  )
)

export default enhance(LiveStats)

import * as errorTypes from '../constants/error'
import polyline from '@mapbox/polyline'
import { getAuthToken } from './getAuthToken'
import { AUTH_KEY, COOKIE_AUTH_KEY, COOKE_AUTH_ID } from '../constants/authKey'
import { deleteCookie } from './cookieUtils'

const axios = require('axios')

const client = axios.create({
  baseURL: process.env.REACT_APP_REST_API_URL,
  timeout: 45000
})

client.interceptors.response.use(
  res => res,
  err => {
    if (err.response) {
      if (err.response.status === 401) {
        clearAuthTokens()
        window.store.dispatch({
          type: errorTypes.AUTH_ERROR,
          error: err
        })
      } else if (err.response.status >= 500) {
        window.store.dispatch({
          type: errorTypes.API_ERROR,
          error: err
        })
      } else {
        window.store.dispatch({
          type: errorTypes.GLOBAL_ERROR,
          error: err
        })
      }
    }

    return Promise.reject(err)
  }
)

const token = getAuthToken()

if (token) {
  client.defaults.headers = { common: { authorization: token } }
}

export async function login (info) {
  let error = null
  const res = await client.post('/v2/common/Customer/login', info)
    .catch(err => {
      error = err
    })
  if (error) { return }
  window.localStorage.setItem(AUTH_KEY, res.data._id)
  client.defaults.headers = { common: { authorization: res.data._id } }
  return res.data.user
}

export async function logout (info) {
  const id = getAuthToken()
  clearAuthTokens()
  await client.post('/v2/common/Customer/logout', { accessToken: { id } })
}

export async function generateArgosToken (params) {
  const res = await client.post('/v2/argos/Customer/generateArgosToken')
    .then(res => res.data)
  return res
}

export async function getRegions () {
  const res = await client.get('/v2/argos/Region/find')
  return res.data.results
}

export async function getCustomerState () {
  const res = await client.get('/v3/nexus/Customer/getCustomerState')
    .then(res => res.data)
  return res
}

export async function getLiveStats (regionId) {
  const res = await client.get(`v2/boa/Region/getOperationsStats/${regionId}`)
    .then(res => res.data.stats)
  return res
}

export async function fetchFleetPolygons (params) {
  const polygons = await client.get('v2/argos/FleetAccount/findLocations', { params })
    .then(res => res.data.results)
  return polygons
}

export async function fetchTerminalLocations (params) {
  const locations = await client.get('v2/argos/Terminal/findLocations', { params })
    .then(res => res.data.results)
  return locations
}

export async function fetchTerritoryLocations (params) {
  const locations = await client.get('v2/argos/Territory/findLocations', { params })
    .then(res => res.data.results)
  return locations
}

export async function fetchServiceLocationLocations (params) {
  const locations = await client.get('v2/argos/ServiceLocation/findLocations', { params })
    .then(res => res.data.results)
  return locations
}

export async function fetchDrivers (params) {
  const drivers = await client.get('v2/boa/Driver', { params })
    .then(res => res.data.drivers)
  return drivers
}

export async function getFleetRouteForDriver (params) {
  const routes = await client.get('v2/argos/FleetDriverAssignment/getFleetRouteForDriver', { params })
    .then(res => res.data)
  return routes
}

export async function getDirectionsForLocations (locations) {
  if (!locations || locations.length < 2) {
    return []
  }

  const coords = locations.map(location => {
    const coordinates = location.geometry.coordinates
    return `${coordinates[0]},${coordinates[1]}`
  }).join(';')

  const res = await axios.get('https://api.mapbox.com/directions/v5/mapbox/driving/' + coords, {
    params: Object.assign({}, {
      geometries: 'polyline',
      access_token: 'pk.eyJ1IjoicWltaW5nZmFuZyIsImEiOiJjaXFjeHE5bjYwMmI1ZmpucDIwbDJ2c3BhIn0.1GyI_6KJ7H9dYNVluF6dSg'
    })
  }).then(res => res.data)

  if (res) {
    if (res.routes.length > 0) {
      const [route] = res.routes

      if (route.geometry) {
        return polyline.decode(route.geometry)
      }
    }
  }

  return []
}

function clearAuthTokens () {
  deleteCookie(COOKIE_AUTH_KEY)
  deleteCookie(COOKE_AUTH_ID)
  window.localStorage.removeItem('argosSettings')
  window.localStorage.removeItem('auth_token')
  window.localStorage.removeItem('persist:root')
}

import { createStore, compose, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import promiseMiddleware from 'redux-promise-middleware'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import isTruthy from './util/isTruthy'

import rootReducer from './reducers'
import middleware from './middleware'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['regions', 'map'],
  stateReconciler: autoMergeLevel2
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

var initialState = {}

const configureStore = () => {
  const store = createStore(
    persistedReducer,
    initialState,
    isTruthy(process.env.NODE_ENV === 'development')
      ? composeWithDevTools(
        applyMiddleware(...middleware, promiseMiddleware(), thunk)
      )
      : compose(applyMiddleware(...middleware, promiseMiddleware(), thunk))
  )
  const persistor = persistStore(store)
  window.store = store
  return { store, persistor }
}

export default configureStore

import React, { Component } from 'react'
import { AccountCircle, Map } from '@material-ui/icons'
import { compose } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import styled from 'styled-components'

import {
  AppBar,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@material-ui/core'

import actions from '../actions'

const DATE_FORMAT = 'HH:mm MM-DD-YYYY z'

const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const StyledAppBar = styled(AppBar)`
  height: 6%;
`

const StyledSpan = styled.span`
  font-size: 1.15em;
`

const DateSmallFont = styled.small`
  font-size: 95%;
`

class Navbar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null,
      regionAnchorEl: null
    }
  }

  handleRegionSelection (region, e) {
    this.props.selectRegion(region._id)
    this.handleRegionMenuClose()
    this.props.setMapCenter(region.location)
  }

  handleMenu (event) {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleRegionMenu (event) {
    this.setState({ regionAnchorEl: event.currentTarget })
  }

  handleRegionMenuClose (event) {
    this.setState({ regionAnchorEl: null })
  }

  handleClose () {
    this.setState({ anchorEl: null })
  }

  handleLogOut () {
    this.setState({ anchorEl: null })
    const action = actions.customer.logout()
    action.payload.then(() => {
      window.location = '/login'
    })
    window.store.dispatch(action)
  }

  componentDidMount () {
    this.props.fetchRegions()
  }

  componentDidUpdate (prevProps) {
    // Handle cases where regions hasn't finished loading
    if (this.props.regions.allRegions.length === 0) { return }

    // If the customer doesn't have a region, use the first region.
    const regionId = this.props.customer.regionId || this.props.regions.allRegions[0]._id

    if (this.props.regions.allRegions !== prevProps.regions.allRegions) {
      if (!this.props.regions.selectedRegion) {
        this.props.selectRegion(regionId)
        const region = this.props.regions.allRegions.find(r => r._id === regionId)
        this.props.setMapCenter(region.location)
      }
    }
  }

  renderRegions () {
    const regions = this.props.regions.allRegions.map(r => {
      const handleRegionSelection = this.handleRegionSelection.bind(this, r)
      return (
        <MenuItem onClick={handleRegionSelection} key={r._id}>{r.name}</MenuItem>
      )
    })
    return regions
  }

  render () {
    const { regions } = this.props
    const { anchorEl, regionAnchorEl } = this.state
    const isMenuOpen = Boolean(anchorEl)
    const isRegionMenuOpen = Boolean(regionAnchorEl)

    return (
      <StyledAppBar position='static'>
        <Toolbar>
          <Grid container justify='space-between' alignItems='center'>
            <Grid item>
              <Typography
                variant='h6'
                color='inherit'
              >
                Argos
              </Typography>
            </Grid>
            <Grid item>
              { regions.selectedRegion &&
                <FlexDiv>
                  <StyledSpan>
                    <strong>{regions.selectedRegion.name}</strong>
                  </StyledSpan>
                  <DateSmallFont>{moment.tz(regions.selectedRegion.timezone).format(DATE_FORMAT)}</DateSmallFont>
                </FlexDiv>
              }
            </Grid>
            <Grid item>
              <IconButton
                color='inherit'
                onClick={(e) => this.handleRegionMenu(e)}
              >
                <Map />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={regionAnchorEl}

                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={isRegionMenuOpen}
                onClose={() => this.handleRegionMenuClose()}>
                {this.renderRegions()}
              </Menu>
              <IconButton
                aria-owns={isMenuOpen ? 'menu-appbar' : null}
                aria-haspopup='true'
                onClick={(e) => this.handleMenu(e)}
                color='inherit'>
                <AccountCircle />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={isMenuOpen}
                onClose={() => this.handleClose()}>
                <MenuItem onClick={() => this.handleLogOut()}>Log Out</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </StyledAppBar>
    )
  }
}

const enhance = compose(
  connect(
    state => ({
      regions: state.regions,
      customer: state.customer
    }),
    {
      fetchRegions: actions.regions.fetchRegions,
      selectRegion: actions.regions.selectRegion,
      setMapCenter: actions.map.setMapCenter
    }
  )
)

export default enhance(Navbar)

import React from 'react'
import { List, Typography, Toolbar } from '@material-ui/core'
import { ActiveTankerListItem } from '.'
import propTypes from 'prop-types'
import styled from 'styled-components'

const StyledToolbar = styled(Toolbar)`
  background-color: #ece6ff;
  justify-content: center;
  height: 5%;
`

const ScrollableList = styled(List)`
  height: 87.5%;
  overflow-y: scroll;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

const ActiveTankerList = (props) => {
  const { tankers, selectedTankerId, onTankerSelect } = props
  return (
    <>
      <StyledToolbar>
        <Typography
          variant='h6'
          align='center'
          color='textPrimary'
        >
          <strong>ACTIVE TANKERS</strong>
        </Typography>
      </StyledToolbar>
      <ScrollableList>
        {tankers.map(tanker => {
          return (
            <ActiveTankerListItem
              key={tanker._id}
              tanker={tanker}
              selectedTankerId={selectedTankerId}
              onTankerSelect={onTankerSelect}
            />
          )
        })}
      </ScrollableList>
    </>
  )
}

ActiveTankerList.propTypes = {
  tankers: propTypes.array.isRequired,
  selectedTankerId: propTypes.string,
  onTankerSelect: propTypes.func.isRequired
}

export default ActiveTankerList

const initialState = {
  requests: [],
  fuelTypeFilter: 'ALL',
  driverFilter: null,
  pinFilter: 'NONE'
}

export default function (state = initialState, action) {
  if (action.error) return state

  switch (action.type) {
    case 'SET_FUEL_REQUESTS':
      return {
        ...state,
        requests: action.payload
      }

    case 'FR_SET_FUEL_TYPE_FILTER':
      return {
        ...state,
        fuelTypeFilter: action.payload
      }

    case 'FR_SET_DRIVER_FILTER':
      return {
        ...state,
        driverFilter: action.payload
      }

    case 'FR_CLEAR_DRIVER_FILTER':
      return {
        ...state,
        driverFilter: null
      }

    case 'FR_SET_PIN_FILTER':
      return {
        ...state,
        pinFilter: action.payload
      }

    default:
      return state
  }
}

import moment from 'moment'

const twelveHoursBefore = moment().subtract(12, 'hours').startOf('hour')
const twelveHoursAfter = moment().add(12, 'hours').startOf('hour')

const initialState = {
  fleetDelivery: {
    minStart: twelveHoursBefore.clone().toDate(),
    maxStart: twelveHoursAfter.clone().toDate(),
    minValue: twelveHoursBefore.clone().toDate(),
    maxValue: twelveHoursAfter.clone().toDate()
  },
  fuelRequest: {
    minCompleteBy: twelveHoursBefore.clone().toDate(),
    maxCompleteBy: twelveHoursAfter.clone().toDate(),
    minValue: twelveHoursBefore.clone().toDate(),
    maxValue: twelveHoursAfter.clone().toDate()
  }
}

export default function (state = initialState, action) {
  if (action.error) return state

  switch (action.type) {
    case 'SET_TIME_FILTER':
      return {
        ...state,
        ...action.payload
      } || {}

    default:
      return state
  }
}

import React from 'react'
import { useSelector } from 'react-redux'
import { SituationalAwarenessStats } from '.'

const SituationalAwarenessStatsContainer = props => {
  const fleetDelivery = useSelector(state => state.situationalAwareness.currentFleetDelivery)
  const selectedTankerId = useSelector(state => state.situationalAwareness.selectedTankerId)
  const fleetAccounts = useSelector(state => state.fleetAccount)
  return (
    <SituationalAwarenessStats
      fleetDelivery={fleetDelivery}
      selectedTankerId={selectedTankerId}
      fleetAccount={fleetDelivery ? fleetAccounts[fleetDelivery.fleetAccountId] : null}
    />
  )
}

export default SituationalAwarenessStatsContainer

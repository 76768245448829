import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import { Layer, Popup } from 'react-mapbox-gl'
import { FuelRequestPopup } from '../MapboxPopups'
import { blue, green, purple, yellow, red, orange, grey } from '@material-ui/core/colors'

export const ServiceProClaimedPinLayer = (props) => {
  const [ selectedFuelRequest, selectFuelRequestForPopup ] = useState(null)
  const driverFilter = useSelector(state => state.fuelRequest.driverFilter)

  function selectFuelRequest (fuelRequest) {
    selectFuelRequestForPopup(fuelRequest)
  }

  function deselectFuelRequest () {
    selectFuelRequestForPopup(null)
  }

  return (
    <Fragment>
      {driverFilter ? <Fragment>
        <Layer
          id='kasparov-fr'
          type='circle'
          sourceId='unclusteredFuelRequestSource'
          paint={{
            'circle-color': [
              'case',
              ['!=', ['get', 'driverId'], driverFilter], grey.A700,
              ['==', ['get', 'status'], 'PAUSED'], blue.A700,
              [
                'match',
                ['get', 'priorityGroup'],
                'COMPLETE_WITHIN_15MIN', purple.A700,
                'COMPLETE_WITHIN_30MIN', red.A700,
                'COMPLETE_WITHIN_1HR', orange.A700,
                'COMPLETE_WITHIN_2HR', yellow.A700,
                'COMPLETE_BY_12PM', yellow.A700,
                'COMPLETE_BY_END_OF_DAY', green.A700,
                green.A700
              ]
            ],
            'circle-radius': 10,
            'circle-stroke-width': [
              'case',
              ['==', ['get', 'status'], 'PAUSED'], 10,
              ['==', ['get', 'fuelType'], 'PREMIUM'], 10,
              1
            ],
            'circle-stroke-color': [
              'case',
              ['==', ['get', 'status'], 'PAUSED'], 'red',
              ['==', ['get', 'fuelType'], 'PREMIUM'], 'yellow',
              '#fff'
            ],
            'circle-opacity': 0.85
          }}
          onClick={e => selectFuelRequest(e.features[0])}
        />
        <Layer
          id='sp-claimed'
          type='symbol'
          sourceId='unclusteredFuelRequestSource'
          layout={{
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'text-field': [
              'concat',
              [
                'case',
                ['!=', driverFilter, ['get', 'driverId']], '',
                ['has', 'name'], ['get', 'name'],
                ''
              ],
              [
                'case',
                ['!=', driverFilter, ['get', 'driverId']], '',
                ['has', 'driverQueueOrder'], ['concat', ', ', ['get', 'driverQueueOrder']],
                ''
              ]
            ]
          }}
        />
      </Fragment>
        : <Fragment>
          <Layer
            id='kasparov-fr'
            type='circle'
            sourceId='unclusteredFuelRequestSource'
            paint={{
              'circle-color': [
                'case',
                ['==', ['get', 'status'], 'PAUSED'], blue.A700,
                [
                  'match',
                  ['get', 'priorityGroup'],
                  'COMPLETE_WITHIN_15MIN', purple.A700,
                  'COMPLETE_WITHIN_30MIN', red.A700,
                  'COMPLETE_WITHIN_1HR', orange.A700,
                  'COMPLETE_WITHIN_2HR', yellow.A700,
                  'COMPLETE_BY_12PM', yellow.A700,
                  'COMPLETE_BY_END_OF_DAY', green.A700,
                  green.A700
                ]
              ],
              'circle-radius': 10,
              'circle-stroke-width': [
                'case',
                ['==', ['get', 'status'], 'PAUSED'], 10,
                ['==', ['get', 'fuelType'], 'PREMIUM'], 10,
                1
              ],
              'circle-stroke-color': [
                'case',
                ['==', ['get', 'status'], 'PAUSED'], 'red',
                ['==', ['get', 'fuelType'], 'PREMIUM'], 'yellow',
                '#fff'
              ],
              'circle-opacity': 0.85
            }}
            onClick={e => selectFuelRequest(e.features[0])}
          />
          <Layer
            id='sp-claimed'
            type='symbol'
            sourceId='unclusteredFuelRequestSource'
            layout={{
              'icon-allow-overlap': true,
              'text-allow-overlap': true,
              'text-field': [
                'concat',
                [
                  'case',
                  ['has', 'name'], ['get', 'name'],
                  ''
                ],
                [
                  'case',
                  ['!=', ['get', 'driverQueueOrder'], null], ['concat', ', ', ['get', 'driverQueueOrder']],
                  ''
                ]
              ]
            }}
          />
        </Fragment>}
      {selectedFuelRequest &&
      <Popup
        onClick={deselectFuelRequest}
        coordinates={selectedFuelRequest.geometry.coordinates}
      >
        <FuelRequestPopup fuelRequest={selectedFuelRequest} />
      </Popup>
      }
    </Fragment>
  )
}

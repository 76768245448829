import * as api from '../util/api'
import centroid from '@turf/centroid'
import moment from 'moment-timezone'
import { tilesets } from '../constants/mapboxTilesets'

export function fetchTankersWithData (data) {
  return {
    type: 'FETCH_TANKERS_FULFILLED',
    payload: data
  }
}

export function setTankers (tankers = []) {
  return {
    type: 'SET_TANKERS',
    payload: tankers,
    options: {
      hideFromConsole: true
    }
  }
}

function getFleetRouteForDriver (params) {
  return {
    type: 'GET_ROUTE_FOR_DRIVER',
    payload: api.getFleetRouteForDriver(params)
  }
}

function getDirectionsForLocations (locations) {
  return {
    type: 'GET_DIRECTION_FOR_LOCATIONS',
    payload: api.getDirectionsForLocations(locations)
  }
}

function setRouteAndDirections (route, directions) {
  return {
    type: 'SET_ROUTE_AND_DIRECTIONS',
    payload: {
      route,
      directions
    }
  }
}

export function setFuelTypeFilter (fuelType) {
  return {
    type: 'TANKER_SET_FUEL_TYPE_FILTER',
    payload: fuelType
  }
}

export function toggleTankerExpansion () {
  return {
    type: 'TOGGLE_TANKER_EXPANSION'
  }
}

export function selectTanker (tanker) {
  return {
    type: 'SELECT_TANKER',
    payload: tanker
  }
}

export function getFullRouteForDriver (params) {
  return async (dispatch, getState) => {
    let locations
    let route
    const { tankers, fleetAccount } = getState()

    const currentTanker = tankers.tankers.find(t => t.currentDriverId === params.driverId)
    const startingLocation = currentTanker && currentTanker.lastLocation
      ? currentTanker.lastLocation
      : null

    const res = await dispatch(getFleetRouteForDriver(params))

    route = (res.value || [])
      .map(r => Object.assign({}, r, {
        fleetAccount: r.fleetAccountId
          ? fleetAccount[r.fleetAccountId]
          : null
      }))
      .filter(r => r.fleetAccount != null)

    if (route.length > 0) {
      locations = route.map(r => centroid(r.fleetAccount.location))

      if (startingLocation) {
        locations = [startingLocation].concat(locations)
      }
      const directions = await dispatch(getDirectionsForLocations(locations))
      dispatch(setRouteAndDirections(route, directions.value))
      return
    }

    // get consumer routing
    route = await dispatch(getConsumerRouteForDriver(params.driverId))
    if (route.length === 0) { return [] }
    const chunkedRoute = chunkRoutesWithOverlap(route)
    const chunkedLocations = chunkedRoute.map(subRoute => {
      return subRoute.map(fr => fr.location)
    })
    if (startingLocation) {
      chunkedLocations[0] = [startingLocation, ...chunkedLocations[0]]
    }
    const chunkedDirections = []
    for (const locations of chunkedLocations) {
      const directions = await dispatch(getDirectionsForLocations(locations))
      chunkedDirections.push(...directions.value)
    }
    dispatch(setRouteAndDirections(route, chunkedDirections))
  }
}

function getConsumerRouteForDriver (driverId) {
  return async (dispatch, getState) => {
    const { fuelRequest } = getState()
    const route = fuelRequest.requests
      .filter(fr => fr.driverId === driverId)
    route.sort((a, b) => {
      return a.driverQueueOrder - b.driverQueueOrder
    })
    return route
  }
}

function chunkRoutesWithOverlap (stops) {
  const chunkSize = 23
  if (stops.length === 0) { return [] }
  const numberOfSubRoutes = Math.ceil(stops.length / chunkSize)
  const subRoutes = []
  for (let i = 0; i < numberOfSubRoutes; i++) {
    subRoutes.push([])
  }
  let subRouteIndex = 0
  stops.forEach((fr, i) => {
    if ((i + 1) % chunkSize === 0) {
      subRoutes[subRouteIndex].push(fr)
      subRouteIndex++
    }
    if (subRouteIndex !== subRoutes.length) {
      subRoutes[subRouteIndex].push(fr)
    }
  })
  return subRoutes
}

export function clearRoute () {
  return {
    type: 'CLEAR_ROUTE'
  }
}

export function setMapCenter (location) {
  const center = centroid(location)
  return {
    type: 'SET_MAP_CENTER',
    payload: center.geometry.coordinates
  }
}

export function setMapZoom (zoom) {
  return {
    type: 'SET_MAP_ZOOM',
    payload: [zoom]
  }
}

export function setTileset (tilesetType) {
  const guessedTimezone = moment.tz.guess()
  const hour = moment.tz(moment(), guessedTimezone).hours()
  let mode
  if (hour >= 18 || hour <= 6) {
    mode = 'dark'
  } else {
    mode = 'light'
  }
  return {
    type: 'SET_TILESET',
    payload: {
      tileset: tilesets[tilesetType][mode],
      tilesetType: tilesetType
    }
  }
}

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Search, LocalShipping } from '@material-ui/icons'
import {
  setMapCenter,
  setMapZoom,
  selectTanker,
  getFullRouteForDriver,
  clearRoute
} from '../../actions/map'
import { setDriverFilter, clearDriverFilter } from '../../actions/fuelRequest'
import { createFeatureCollection } from '../../util/geojsonUtils'
import moment from 'moment-timezone'
import styled from 'styled-components'

const StyledDiv = styled.div`
  display: flex;
`

export const TankerAndSPSearch = (props) => {
  const [ isExpanded, setIsExpanded ] = useState(false)
  const [ searchList, setSearchList ] = useState([])
  const dispatch = useDispatch()
  const drivers = useSelector(state => state.drivers)
  const tankers = useSelector(state => state.tankers.tankers)
  const fleetDeliveryFilter = useSelector(state => state.timeFilter.fleetDelivery)

  // Used for the initial update of the list
  useEffect(() => {
    if (!Object.keys(drivers).length) {
      return
    }
    if (!tankers.length) {
      return
    }
    if (!searchList.length) {
      updateSearchItems(tankers, drivers, setSearchList)
    }
  }, [tankers, drivers, searchList])

  // Used to only update every 5th second.
  useEffect(() => {
    if (!Object.keys(drivers).length) {
      return
    }
    if (!tankers.length) {
      return
    }
    // Only reupdate the list every 5 seconds because doing it every time a tanker moves is excessive
    if (moment().seconds() % 5 !== 0) {
      return
    }
    updateSearchItems(tankers, drivers, setSearchList)
  }, [tankers, drivers])

  function handleTankerSelection (tanker) {
    const tankerGeoJSON = createFeatureCollection([tanker]).features[0]
    dispatch(selectTanker(tankerGeoJSON))
    if (tankerGeoJSON) {
      dispatch(getFullRouteForDriver({
        driverId: tankerGeoJSON.properties.currentDriverId,
        from: fleetDeliveryFilter.minStart,
        to: fleetDeliveryFilter.maxStart
      }))
      dispatch(setDriverFilter(tankerGeoJSON.properties.currentDriverId))
    }
  }

  function clearDriverRoute () {
    dispatch(clearRoute())
    dispatch(clearDriverFilter())
  }

  return (
    <div>
      {
        isExpanded
          ? <StyledDiv>
            <Autocomplete
              id='SP-tanker-search'
              onChange={(e, v) => {
                if (v == null) {
                  dispatch(setMapZoom(10))
                  clearDriverRoute()
                  dispatch(selectTanker(null))
                  return
                }
                clearDriverRoute()
                handleTankerSelection(v)
                dispatch(setMapCenter(v.lastLocation))
                dispatch(setMapZoom(15))
              }}
              clearOnEscape
              options={searchList}
              getOptionLabel={option => option.driverFullName || option.shortId}
              style={{ width: 300 }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='SP or Tanker'
                  variant='outlined'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  size='small'
                />
              )}
            />
          </StyledDiv>
          : <Button
            color='primary'
            variant='contained'
            size='small'
            onClick={() => setIsExpanded(true)}
            disabled={!Object.keys(drivers).length}
          >
            <Search /><LocalShipping />
          </Button>
      }
    </div>
  )
}

const updateSearchItems = (tankers, drivers, setSearchList) => {
  const driversInTankers = tankers.map(tanker => {
    const driver = drivers[tanker.currentDriverId]
    const driverFullName = `${driver.firstName} ${driver.lastName}`
    return {
      ...tanker,
      driverFullName
    }
  })
  setSearchList([ ...tankers, ...driversInTankers ])
}

import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'
import actions from '../../actions'
import { LocalShipping } from '@material-ui/icons'
import {
  Popover,
  Button,
  Radio,
  RadioGroup,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel
} from '@material-ui/core'

const FormContainer = styled.div`
  padding: 10%;
`

class TankerControl extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isMenuOpen: false,
      menuAnchorEl: null
    }
  }

  handleFuelTypeSelection (event) {
    this.props.setFuelTypeFilter(event.target.value)
  }

  handleMenuOpen (e) {
    this.setState({
      menuAnchorEl: e.currentTarget,
      isMenuOpen: true
    })
  }

  handleMenuClose () {
    this.setState({
      menuAnchorEl: null,
      isMenuOpen: false
    })
  }

  renderFuelTypeItems () {
    const fuelTypes = ['ALL', 'REGULAR', 'PREMIUM', 'DIESEL']
    const { fuelTypeFilter, expandedTankers } = this.props
    return (
      <FormContainer>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>Tanker</FormLabel>
          <RadioGroup name='tanker-fueltype'
            value={fuelTypeFilter}
            onChange={(e) => this.handleFuelTypeSelection(e)}
          >
            {fuelTypes.map((ft) =>
              <FormControlLabel value={ft} control={<Radio />} label={ft.toLowerCase()} key={ft} />
            )}
          </RadioGroup>
          <FormLabel component='legend'>Tanker View</FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={expandedTankers}
                onChange={() => this.props.toggleTankerExpansion()}
              />
            }
            label='expanded'
          />
        </FormControl>
      </FormContainer>
    )
  }

  renderPopover () {
    const { menuAnchorEl, isMenuOpen } = this.state
    return (
      <Popover
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={isMenuOpen}
        onClose={() => this.handleMenuClose()}
      >
        {this.renderFuelTypeItems()}
      </Popover>
    )
  }

  renderButton () {
    return (
      <Button
        color='secondary'
        variant='outlined'
        onClick={(e) => this.handleMenuOpen(e)}
      >
        <LocalShipping />
      </Button>
    )
  }

  render () {
    return (
      <Fragment>
        {this.renderButton()}
        {this.renderPopover()}
      </Fragment>
    )
  }
}

const enhance = compose(
  connect(
    state => ({
      customer: state.customer,
      fuelTypeFilter: state.tankers.fuelTypeFilter,
      expandedTankers: state.tankers.expandedTankers
    }),
    {
      setFuelTypeFilter: actions.map.setFuelTypeFilter,
      toggleTankerExpansion: actions.map.toggleTankerExpansion
    }
  )
)

export default enhance(TankerControl)

import React, { useState, Fragment } from 'react'
import { Layer } from 'react-mapbox-gl'
import { HoverOverTextLayer } from '.'

export const TerminalPolygonLayer = (props) => {
  const [ terminal, setTerminal ] = useState(null)
  const [ selectedCoordinates, setCoordinates ] = useState(null)
  return (
    <Fragment>
      <HoverOverTextLayer
        coordinates={selectedCoordinates}
        properties={terminal}
      />
      <Layer
        id='terminal-polygons'
        type='fill'
        sourceId='terminalLocations'
        paint={{
          'fill-color': '#add8e6',
          'fill-opacity': 0.75,
          'fill-outline-color': '#02075D'
        }}
        onMouseEnter={(e) => {
          const { lng, lat } = e.lngLat
          setCoordinates([lng, lat])
          setTerminal(e.features[0].properties)
        }}
        onMouseLeave={e => {
          setTerminal(null)
          setCoordinates(null)
        }}
      />
    </Fragment>

  )
}

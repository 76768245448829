import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TilesetControl } from '.'
import actions from '../../actions'

const TilesetControlContainer = props => {
  const dispatch = useDispatch()
  const tilesetType = useSelector(state => state.map.tilesetType)
  const setTileset = (type) => dispatch(actions.map.setTileset(type))

  return (
    <TilesetControl
      tilesetType={tilesetType}
      handleTilesetChange={setTileset}
    />
  )
}

export default TilesetControlContainer

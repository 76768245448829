import { AUTH_KEY } from '../constants/authKey'
import { StorageFactory } from '../util/localStorage'
import { getCookie } from './cookieUtils'

export function getAuthToken () {
  // first check cookies for SSO
  const accessTokenId = getCookie('accessTokenId')

  if (accessTokenId) {
    return accessTokenId
  }

  const localStore = StorageFactory(() => window.localStorage)
  return localStore.getItem(AUTH_KEY)
}

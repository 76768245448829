import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Slider from 'rc-slider'
import styled from 'styled-components'
import actions from '../actions'
import moment from 'moment-timezone'
import 'rc-slider/assets/index.css'
import { Typography, Grid } from '@material-ui/core'
const Range = Slider.createSliderWithTooltip(Slider.Range)
const DATE_FORMAT = 'HH:mm  MM/DD z'
const tz = 'America/Los_Angeles'

const FlexContainer = styled.div`
  display: flex;
  margin: 5px 10px;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  overflow: hidden;
`

const StyledSliderContainer = styled.div`
  margin: 0 15px;
  flex-grow: 1;
  align-items: center;
`

class FleetDeliveryTimeSlider extends Component {
  constructor (props) {
    super(props)
    this.duringChangeHandler = this.duringChangeHandler.bind(this)
    this.changeCompleteHandler = this.changeCompleteHandler.bind(this)
    this.state = {
      sliderValues: {
        start: moment.tz(tz).startOf('hour').subtract(12, 'hours').format(DATE_FORMAT),
        end: moment.tz(tz).startOf('hour').add(12, 'hours').format(DATE_FORMAT)
      }
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.regions.selectedRegion !== prevProps.regions.selectedRegion) {
      const { timezone } = this.props.regions.selectedRegion
      this.setState({
        sliderValues: {
          start: moment.tz(timezone).startOf('hour').subtract(12, 'hours').format(DATE_FORMAT),
          end: moment.tz(timezone).startOf('hour').add(12, 'hours').format(DATE_FORMAT)
        }
      })
      this.props.setTimeFilter({
        fleetDelivery: {
          ...this.props.timeFilter.fleetDelivery,
          minStart: moment.tz(timezone).startOf('hour').subtract(12, 'hours').toDate(),
          maxStart: moment.tz(timezone).startOf('hour').add(12, 'hours').toDate()
        }
      })
    }
  }

  duringChangeHandler (time) {
    const { timezone } = this.props.regions.selectedRegion
    this.setState({
      sliderValues: {
        start: moment.tz(time[0], 'X', timezone).format(DATE_FORMAT),
        end: moment.tz(time[1], 'X', timezone).format(DATE_FORMAT)
      }
    })
  }

  changeCompleteHandler (time) {
    const { timezone } = this.props.regions.selectedRegion
    this.props.setTimeFilter({
      fleetDelivery: {
        ...this.props.timeFilter.fleetDelivery,
        minStart: moment.tz(time[0], 'X', timezone).toDate(),
        maxStart: moment.tz(time[1], 'X', timezone).toDate()
      }
    })
  }

  render () {
    const { timezone } = this.props.regions.selectedRegion || 'America/Los_Angeles'
    const { start, end } = this.state.sliderValues
    const {
      minValue,
      maxValue,
      minStart,
      maxStart
    } = this.props.timeFilter.fleetDelivery
    return (
      <>
        <FlexContainer>
          <StyledSliderContainer>
            <Typography align='center'>
              <small>DELIVERY START TIME</small>
            </Typography>
            <Range
              min={minValue.getTime() / 1000}
              max={maxValue.getTime() / 1000}
              defaultValue={[minStart.getTime() / 1000, maxStart.getTime() / 1000]}
              onAfterChange={this.changeCompleteHandler}
              onChange={this.duringChangeHandler}
              step={900}
              tipFormatter={val => moment.tz(val, 'X', timezone).format('HH:mm')}
              trackStyle={[{ background: 'red' }]}
              railStyle={{ background: 'rgba(255, 0, 0, 0.15)' }}
              handleStyle={[{ borderColor: 'red' }, { borderColor: 'red' }]}
            />
            <Grid container spacing={4}>
              <Grid item>
                <small>{start}</small>
              </Grid>
              <Grid item>
                <small>{end}</small>
              </Grid>
            </Grid>
          </StyledSliderContainer>
        </FlexContainer>
      </>
    )
  }
}

const enhance = compose(
  connect(
    state => ({
      timeFilter: state.timeFilter || {},
      regions: state.regions || {}
    }),
    {
      setTimeFilter: actions.timeFilter.setTimeFilter
    }
  )
)

export default enhance(FleetDeliveryTimeSlider)

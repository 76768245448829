import React, { useState, Fragment } from 'react'
import { Layer } from 'react-mapbox-gl'
import { HoverOverTextLayer } from '.'

export const FleetAccountPolygonLayer = (props) => {
  const [ fleetAccount, setFleetAccount ] = useState(null)
  const [ selectedCoordinates, setCoordinates ] = useState(null)
  return (
    <Fragment>
      <HoverOverTextLayer
        coordinates={selectedCoordinates}
        properties={fleetAccount}
      />
      <Layer
        id='fa-polygons'
        type='fill'
        sourceId='fleetLocations'
        paint={{
          'fill-color': '#FF0000',
          'fill-opacity': 0.25,
          'fill-outline-color': '#8b0000'
        }}
        onMouseEnter={(e) => {
          const { lng, lat } = e.lngLat
          setCoordinates([lng, lat])
          setFleetAccount(e.features[0].properties)
        }}
        onMouseLeave={e => {
          setFleetAccount(null)
          setCoordinates(null)
        }}
      />
    </Fragment>
  )
}

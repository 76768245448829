import React from 'react'
import { Layer, Popup } from 'react-mapbox-gl'
import { deepPurple } from '@material-ui/core/colors'
import { TankerPopup } from '../MapboxPopups'
import { useDispatch, useSelector } from 'react-redux'
import { setDriverFilter, clearDriverFilter } from '../../actions/fuelRequest'
import { getFullRouteForDriver, clearRoute, selectTanker } from '../../actions/map'

export const TankerLayer = (props) => {
  const dispatch = useDispatch()
  const expandedTankers = useSelector(state => state.tankers.expandedTankers)
  const fleetDeliveryFilter = useSelector(state => state.timeFilter.fleetDelivery)
  const selectedTanker = useSelector(state => state.tankers.selectedTanker)

  function handleTankerSelection (tanker) {
    dispatch(selectTanker(tanker))
    if (tanker) {
      dispatch(getFullRouteForDriver({
        driverId: tanker.properties.currentDriverId,
        from: fleetDeliveryFilter.minStart,
        to: fleetDeliveryFilter.maxStart
      }))
      dispatch(setDriverFilter(tanker.properties.currentDriverId))
    }
  }

  function clearDriverRoute () {
    dispatch(clearRoute())
    dispatch(clearDriverFilter())
  }

  return (
    <>
      <Layer
        id='minimal-tankers'
        type='symbol'
        sourceId='tankerLocations'
        filter={['==', ['get', 'isOptimusEnabled'], false]}
        layout={{
          'icon-image': [
            'case',
            ['==', ['get', 'type'], 'TONKA'], 'tanker-top',
            ['==', ['get', 'type'], 'PICKUP'], 'f250-top',
            'car-15'
          ],
          'icon-size': expandedTankers ? 0.25 : tankerInterpolationExpression,
          'icon-allow-overlap': true,
          ...iconRotateLayoutExpression,
          ...tankerTextLayoutExpresion
        }}
        paint={tankerTextPaintExpression}
        onClick={e => handleTankerSelection(e.features[0])}
      />
      <Layer
        id='optimus-enabled'
        type='symbol'
        sourceId='tankerLocations'
        filter={['==', ['get', 'isOptimusEnabled'], true]}
        layout={{
          'icon-image': 'opt-tanker-top',
          'icon-allow-overlap': true,
          'icon-size': expandedTankers ? 0.25 : tankerInterpolationExpression,
          ...iconRotateLayoutExpression,
          ...tankerTextLayoutExpresion
        }}
        paint={tankerTextPaintExpression}
        onClick={e => handleTankerSelection(e.features[0])}
      />
      {!expandedTankers &&
        <Layer
          id='minimal-opt-flag'
          type='symbol'
          sourceId='tankerLocations'
          filter={['==', ['get', 'isOptimusEnabled'], true]}
          layout={{
            'icon-image': 'opt-icon',
            'icon-size': 0.15,
            ...iconRotateLayoutExpression
          }}
        />}
      {expandedTankers &&
        <>
          <Layer
            id='tanker-text'
            type='symbol'
            sourceId='tankerLocations'
            layout={{
              'text-allow-overlap': true,
              'text-field': [
                'concat',
                ['get', 'firstName', ['get', 'driver']],
                ' ',
                ['get', 'lastName', ['get', 'driver']],
                '\n',
                [
                  'concat',
                  [
                    'case',
                    REGULAR_0, 'REG: ',
                    PREMIUM_0, 'PRE: ',
                    DIESEL_0, 'DSL: ',
                    'N/A'
                  ],
                  ['round', ['get', 'currentFuelLevel', ['at', 0, ['get', 'tanks']]]],
                  '\n'
                ],
                [
                  'concat',
                  [
                    'case',
                    REGULAR_1, 'REG: ',
                    PREMIUM_1, 'PRE: ',
                    DIESEL_1, 'DSL :',
                    'N/A'
                  ],
                  ['round', ['get', 'currentFuelLevel', ['at', 1, ['get', 'tanks']]]],
                  '\n'
                ]
              ],
              'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
              'text-size': 10
            }}
            paint={tankerTextPaintExpression}
          />
        </>}
      {selectedTanker &&
        <Popup
          id='tanker-popup'
          coordinates={selectedTanker.geometry.coordinates}
          onClick={e => {
            dispatch(selectTanker(null))
            clearDriverRoute()
          }}
        >
          <TankerPopup tanker={selectedTanker} />
        </Popup>}
    </>
  )
}

// TODO(dom): figure out a way to dynamically render tanker text for more than 2 tankers
const REGULAR_0 = ['==', ['get', 'currentFuelType', ['at', 0, ['get', 'tanks']]], 'REGULAR']
const REGULAR_1 = ['==', ['get', 'currentFuelType', ['at', 1, ['get', 'tanks']]], 'REGULAR']
const PREMIUM_0 = ['==', ['get', 'currentFuelType', ['at', 0, ['get', 'tanks']]], 'PREMIUM']
const PREMIUM_1 = ['==', ['get', 'currentFuelType', ['at', 1, ['get', 'tanks']]], 'PREMIUM']
const DIESEL_0 = ['==', ['get', 'currentFuelType', ['at', 0, ['get', 'tanks']]], 'DIESEL']
const DIESEL_1 = ['==', ['get', 'currentFuelType', ['at', 1, ['get', 'tanks']]], 'DIESEL']

const tankerInterpolationExpression = [
  'interpolate', ['linear'], ['zoom'],
  4, 0.14,
  15, 0.17
]

const tankerTextLayoutExpresion = {
  'text-field': ['get', 'shortId'],
  'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
  'text-radial-offset': 2.5,
  'text-justify': 'auto',
  'text-size': 12,
  'text-allow-overlap': true
}

const tankerTextPaintExpression = {
  'text-color': deepPurple['900'],
  'text-halo-color': '#fff',
  'text-halo-width': 5
}

const iconRotateLayoutExpression = {
  'icon-rotate': [
    'case',
    ['has', 'course'], ['get', 'course'],
    0
  ]
}

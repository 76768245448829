import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Search, Business } from '@material-ui/icons'
import {
  setMapCenter,
  setMapZoom
} from '../../actions/map'
import styled from 'styled-components'
import centroid from '@turf/centroid'

const StyledDiv = styled.div`
  display: flex;
`

export const FleetAndConsumerSearch = (props) => {
  const dispatch = useDispatch()
  const [ isExpanded, setIsExpanded ] = useState(false)
  const [ searchList, setSearchList ] = useState([])
  const selectedRegion = useSelector(state => state.regions.selectedRegion)
  const serviceLocations = useSelector(state => state.serviceLocation)
  const fleetAccountObject = useSelector(state => state.fleetAccount)

  useEffect(() => {
    const currentRegionFleetAccounts = Object.keys(fleetAccountObject)
      .filter(key => fleetAccountObject[key].regionId === selectedRegion._id)
      .map(key => fleetAccountObject[key])
    const currentRegionServiceLocations = serviceLocations
      .filter(sl => sl.regionId === selectedRegion._id)
    setSearchList([...currentRegionServiceLocations, ...currentRegionFleetAccounts])
  }, [serviceLocations, fleetAccountObject, selectedRegion])

  return (
    <div>
      {
        isExpanded
          ? <StyledDiv>
            <Autocomplete
              id='fleet-consumer-search'
              onChange={(e, v) => {
                if (v == null) {
                  dispatch(setMapZoom(10))
                  return
                }
                dispatch(setMapCenter(centroid(v.location)))
                dispatch(setMapZoom(15))
              }}
              clearOnEscape
              options={searchList}
              getOptionLabel={option => option.name}
              style={{ width: 300 }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Fleet Account or Service Location'
                  variant='outlined'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  size='small'
                />
              )}
            />
          </StyledDiv>
          : <Button
            color='primary'
            variant='contained'
            size='small'
            onClick={() => setIsExpanded(true)}
            disabled={!Object.keys(searchList).length}
          >
            <Search /><Business />
          </Button>
      }
    </div>
  )
}

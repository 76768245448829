import React from 'react'
import { Layer } from 'react-mapbox-gl'
import { useSelector } from 'react-redux'

const SituationalAwarenessLayers = (props) => {
  const trip = useSelector(state => state.situationalAwareness.currentTrip)
  if (!trip) {
    return null
  }
  return (
    <>
      <Layer
        id='situational-trip-layer'
        sourceId='situational-trip'
        type='symbol'
        layout={{
          'icon-image': 'trip-icon',
          'icon-allow-overlap': true,
          'icon-size': 1,
          'icon-rotate': [
            'case',
            ['has', 'course'], ['get', 'course'],
            0
          ]
        }}
      />
      <Layer
        id='situational-delivery-circle-layer'
        sourceId='situational-delivery-details'
        type='circle'
        paint={{
          'circle-radius': 14,
          'circle-color': '#60008D'
        }}
      />
      <Layer
        id='situational-delivery-image-layer'
        sourceId='situational-delivery-details'
        type='symbol'
        layout={{
          ...iconExpression
        }}
      />
    </>
  )
}

const iconExpression = {
  'icon-image': [
    'case',
    ['get', 'wasFilled'], 'filled',
    'not-filled'
  ],
  'icon-size': 0.65
}

export default SituationalAwarenessLayers


const initialState = {
  selectedTankerId: null,
  currentFleetDelivery: null,
  currentTrip: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_SITUATIONAL_AWARENESS_DELIVERY':
      return {
        ...state,
        currentFleetDelivery: action.payload
      }
    case 'SET_SITUATIONAL_AWARENESS_TRIP':
      return {
        ...state,
        currentTrip: action.payload
      }
    case 'SET_SITUATIONAL_AWARENESS_TANKER_ID':
      return {
        ...state,
        selectedTankerId: action.payload
      }
    default:
      return state
  }
}

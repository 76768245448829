const initialState = {
  login: false,
  fetchServiceLocations: false,
  fetchReports: false,
  fetchReport: false,
  fetchEventGames: false
}

export default function (state = initialState, action) {
  if (action.error) return state

  switch (action.type) {
    // LOGIN
    case 'LOGIN_PENDING':
      return Object.assign({}, state, {
        login: true
      })
    case 'LOGIN_FULFILLED':
    case 'LOGIN_REJECTED':
      return Object.assign({}, state, {
        login: false
      })

    /**
     * FETCH_SERVICE_LOCATION_BY_ID
     */
    case 'FETCH_SERVICE_LOCATION_BY_ID_PENDING':
      return Object.assign({}, state, {
        fetchServiceLocations: true
      })
    case 'FETCH_SERVICE_LOCATION_BY_ID_FULFILLED':
    case 'FETCH_SERVICE_LOCATION_BY_ID_REJECTED':
      return Object.assign({}, state, {
        fetchServiceLocations: false
      })

    /**
     * FETCH_SERVICE_LOCATIONS
     */
    case 'FETCH_SERVICE_LOCATIONS_PENDING':
      return Object.assign({}, state, {
        fetchServiceLocations: true
      })
    case 'FETCH_SERVICE_LOCATIONS_FULFILLED':
    case 'FETCH_SERVICE_LOCATIONS_REJECTED':
      return Object.assign({}, state, {
        fetchServiceLocations: false
      })

    /**
     * FETCH_CAMPUS_REPORTS
     */
    case 'FETCH_CAMPUS_REPORT_BY_SERVICE_LOCATION_ID_PENDING':
      return Object.assign({}, state, {
        fetchReports: true
      })
    case 'FETCH_CAMPUS_REPORT_BY_SERVICE_LOCATION_ID_FULFILLED':
    case 'FETCH_CAMPUS_REPORT_BY_SERVICE_LOCATION_ID_REJECTED':
      return Object.assign({}, state, {
        fetchReports: false
      })

    /**
     * FETCH_EVENT_GAMES_BY_SERVICE_LOCATION_ID
     */
    case 'FETCH_EVENT_GAMES_BY_SERVICE_LOCATION_ID_PENDING':
      return Object.assign({}, state, {
        fetchEventGames: true
      })
    case 'FETCH_EVENT_GAMES_BY_SERVICE_LOCATION_ID_FULFILLED':
    case 'FETCH_EVENT_GAMES_BY_SERVICE_LOCATION_ID_REJECTED':
      return Object.assign({}, state, {
        fetchEventGames: false
      })

    /**
     * FETCH_CAMPUS_REPORT_DETAILS
     */
    case 'FETCH_CAMPUS_REPORT_DETAILS_PENDING':
      return Object.assign({}, state, {
        fetchReport: true
      })
    case 'FETCH_CAMPUS_REPORT_DETAILS_FULFILLED':
    case 'FETCH_CAMPUS_REPORT_DETAILS_REJECTED':
      return Object.assign({}, state, {
        fetchReport: false
      })

    default:
      return state
  }
}

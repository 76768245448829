import React from 'react'

import {
  CircularProgress
} from '@material-ui/core'

export default (props) => {
  const { size, thickness } = props
  return (
    <div style={{
      position: 'absolute',
      left: '0',
      right: '0',
      top: '0',
      bottom: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress size={size || 60} thickness={thickness || 4} />
    </div>
  )
}

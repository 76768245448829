import * as api from '../util/api'

export function fetchRegions (params) {
  return {
    type: 'FETCH_REGIONS',
    payload: api.getRegions(params)
  }
}

export function selectRegion (params) {
  return {
    type: 'SELECT_REGION',
    payload: params
  }
}

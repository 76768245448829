import { Source } from 'react-mapbox-gl'
import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { isFilteredFuelType } from '../../util/isFilteredFuelType'
import { isFilteredPin } from '../../util/isFilteredPin'
import { createFeatureCollection } from '../../util/geojsonUtils'
import actions from '../../actions'
import { throttle } from 'lodash'

import firebase from '../../util/firebase'
import moment from 'moment'

class FuelRequestSource extends Component {
  constructor (props) {
    super(props)
    this.state = {
      unsubscribeFromCurrentFuelRequestSnapshot: null
    }
  }

  componentDidMount () {
    if (this.props.regions.selectedRegion) {
      this.subscribeToFuelRequestFromFirebase()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.regions.selectedRegion !== prevProps.regions.selectedRegion) {
      if (this.unsubscribeFromCurrentFuelRequestSnapshot) {
        this.unsubscribeFromCurrentFuelRequestSnapshot()
      }
      this.subscribeToFuelRequestFromFirebase()
    }
    if (this.props.timeFilter.fuelRequest !== prevProps.timeFilter.fuelRequest) {
      if (this.unsubscribeFromCurrentFuelRequestSnapshot) {
        this.unsubscribeFromCurrentFuelRequestSnapshot()
      }
      this.subscribeToFuelRequestFromFirebase()
    }
  }

  render () {
    const { drivers } = this.props
    const { minCompleteBy, maxCompleteBy } = this.props.timeFilter.fuelRequest
    const { fuelTypeFilter, pinFilter } = this.props.fuelRequests
    const timeFilteredFuelRequests = this.props.fuelRequests.requests
      .filter(doc =>
        doc.completeByTime != null &&
        moment(doc.completeByTime.seconds, 'X').isSameOrAfter(moment(minCompleteBy)) &&
        moment(doc.completeByTime.seconds, 'X').isBefore(moment(maxCompleteBy)) &&
        isFilteredFuelType([doc.fuelType], fuelTypeFilter) &&
        isFilteredPin(doc, pinFilter))
      .map(fr => {
        const { driverId } = fr
        if (!driverId) {
          return fr
        }
        const driverInfo = {}

        if (drivers && drivers[driverId]) {
          driverInfo.name = `${drivers[driverId].firstName} ${drivers[driverId].lastName}`
        } else {
          driverInfo.name = 'N/A'
        }

        if (fr.driverQueueOrder) {
          driverInfo.driverQueueOrder = fr.driverQueueOrder
        }
        return {
          ...fr,
          ...driverInfo
        }
      })
    const fuelRequestGeoJSON = createFeatureCollection(timeFilteredFuelRequests)

    return (
      <>
        <Source
          id='fuelRequests'
          geoJsonSource={{
            type: 'geojson',
            data: fuelRequestGeoJSON,
            cluster: true,
            clusterMaxZoom: 12,
            clusterRadius: 40,
            clusterProperties: {
              COMPLETE_WITHIN_15MIN: ['+', ['case', COMPLETE_WITHIN_15MIN, 1, 0]],
              COMPLETE_WITHIN_30MIN: ['+', ['case', COMPLETE_WITHIN_30MIN, 1, 0]],
              COMPLETE_WITHIN_1HR: ['+', ['case', COMPLETE_WITHIN_1HR, 1, 0]],
              COMPLETE_WITHIN_2HR: ['+', ['case', COMPLETE_WITHIN_2HR, 1, 0]],
              COMPLETE_BY_12PM: ['+', ['case', COMPLETE_BY_12PM, 1, 0]],
              COMPLETE_BY_END_OF_DAY: ['+', ['case', COMPLETE_BY_END_OF_DAY, 1, 0]]
            }
          }}
        />
        <Source
          id='unclusteredFuelRequestSource'
          geoJsonSource={{
            type: 'geojson',
            data: fuelRequestGeoJSON
          }}
        />
      </>
    )
  }

  unsubscribeFromCurrentFuelRequestSnapshot () {
    const { unsubscribeFromCurrentFuelRequestSnapshot } = this.state
    if (unsubscribeFromCurrentFuelRequestSnapshot) {
      unsubscribeFromCurrentFuelRequestSnapshot()
    }
  }

  subscribeToFuelRequestFromFirebase () {
    const { minCompleteBy, maxCompleteBy } = this.props.timeFilter.fuelRequest
    const { selectedRegion } = this.props.regions
    const fuelRequestSnapshotUnsubscriber = firebase.firestore()
      .collection('FuelRequest')
      .where('completeByTime', '>=', minCompleteBy)
      .where('completeByTime', '<', maxCompleteBy)
      .where('regionId', '==', selectedRegion._id)
      .onSnapshot((data) => {
        this.props.setFuelRequests(data.docs
          .map(doc => doc.data())
          .map(doc => {
            if (!doc.estimatedFuelAmount) {
              doc.estimatedFuelAmount = 12
            }
            return doc
          }))
      })
    this.setState({
      unsubscribeFromCurrentFuelRequestSnapshot: fuelRequestSnapshotUnsubscriber
    })
  }
}

const enhance = compose(
  connect(
    state => ({
      fuelRequests: state.fuelRequest || [],
      regions: state.regions,
      timeFilter: state.timeFilter,
      drivers: state.drivers || {}
    }),
    {
      setFuelRequests: throttle(actions.fuelRequest.setFuelRequests, 1000)
    }
  )
)

const COMPLETE_WITHIN_15MIN = ['==', ['get', 'priorityGroup'], 'COMPLETE_WITHIN_15MIN']
const COMPLETE_WITHIN_30MIN = ['==', ['get', 'priorityGroup'], 'COMPLETE_WITHIN_30MIN']
const COMPLETE_WITHIN_1HR = ['==', ['get', 'priorityGroup'], 'COMPLETE_WITHIN_1HR']
const COMPLETE_WITHIN_2HR = ['==', ['get', 'priorityGroup'], 'COMPLETE_WITHIN_2HR']
const COMPLETE_BY_12PM = ['==', ['get', 'priorityGroup'], 'COMPLETE_BY_12PM']
const COMPLETE_BY_END_OF_DAY = ['==', ['get', 'priorityGroup'], 'COMPLETE_BY_END_OF_DAY']

export default enhance(FuelRequestSource)

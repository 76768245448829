import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import customer from './customer'
import fleetDelivery from './fleetDelivery'
import fuelRequest from './fuelRequest'
import serviceLocation from './serviceLocation'
import spinner from './spinner'
import drivers from './drivers'
import map from './map'
import tankers from './tankers'
import terminal from './terminal'
import territory from './territory'
import error from './error'
import fleetAccount from './fleetAccount'
import timeFilter from './timeFilter'
import tank from './tank'
import regions from './regions'
import liveStats from './liveStats'
import situationalAwareness from './situationalAwareness'

const rootReducer = combineReducers({
  customer,
  tankers,
  terminal,
  territory,
  fuelRequest,
  drivers,
  map,
  fleetAccount,
  serviceLocation,
  spinner,
  error,
  fleetDelivery,
  timeFilter,
  tank,
  regions,
  liveStats,
  situationalAwareness,
  form: formReducer
})

// Wrap final reducer to return an Immutable object
export default function reducer (...args) {
  return rootReducer(...args)
}

import React from 'react'
import propTypes from 'prop-types'
import { Table, TableBody, TableCell, TableRow, Typography, Toolbar, Grid, TableHead, Container } from '@material-ui/core'
import { round } from 'lodash'
import moment from 'moment-timezone'
import styled from 'styled-components'

const StyledContainer = styled(Container)`
  margin-top: 5%;
  margin-bottom: 5%;
`

const StyledToolbar = styled(Toolbar)`
  background-color: #ece6ff;
  justify-content: center;
`

const TableWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 65%;

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

const SituationalAwarenessStats = props => {
  const { fleetDelivery, selectedTankerId, fleetAccount } = props
  const renderDeliveryDetails = (fleetDelivery) => {
    if (!selectedTankerId) {
      return (
        <StyledContainer>
          <Typography><small>Select a tanker to see delivery details</small></Typography>
        </StyledContainer>
      )
    }
    if (!fleetDelivery) {
      return (
        <StyledContainer>
          <Typography><small>No in progress fleet delivery</small></Typography>
        </StyledContainer>
      )
    }
    const totals = getStats(fleetDelivery)
    return (
      <>
        <StyledContainer>
          <Typography><strong>{fleetAccount.name}</strong></Typography>
          <Typography variant='overline'>
            <Grid
              container
              justify='space-between'
            >
              <Grid item xs={10}>Total Gals:</Grid>
              <Grid item xs={2}><strong>{round(totals.totalGallons, 1)}</strong></Grid>
              <Grid item xs={10}>Total Vehicles Processed:</Grid>
              <Grid item xs={2}><strong>{totals.totalProcessed}</strong></Grid>
              <Grid item xs={10}>Total Vehicles Not Filled:</Grid>
              <Grid item xs={2}><strong>{totals.totalNotFilled}</strong></Grid>
              <Grid item xs={10}>Elapsed Time:</Grid>
              <Grid item xs={2}><strong>{totals.elapsed}</strong></Grid>
              <Grid item xs={10}>Gallons per Hour:</Grid>
              <Grid item xs={2}><strong>{totals.gph}</strong></Grid>
            </Grid>
          </Typography>
        </StyledContainer>
        <TableWrapper>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell align='left'><strong>#</strong></TableCell>
                <TableCell align='right'><strong>Gal</strong></TableCell>
                <TableCell align='right'><strong>Reason</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fleetDelivery.deliveryDetails.map((dd, index) => (
                <TableRow
                  key={dd.fleetVehicleId}
                >
                  <TableCell align='left'>{index + 1}</TableCell>
                  <TableCell align='right'>{round(dd.fuelDispensed || 0, 1)}</TableCell>
                  <TableCell align='right'>{dd.notFilledReason}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </>
    )
  }

  return (
    <>
      <StyledToolbar>
        <Typography
          variant='h6'
          align='center'
          color='textPrimary'
        >
          <strong>SELECTED TANKER STATS</strong>
        </Typography>
      </StyledToolbar>
      {renderDeliveryDetails(fleetDelivery)}
    </>
  )
}

const getStats = (delivery) => {
  const initialStats = {
    totalProcessed: 0,
    totalNotFilled: 0,
    totalGallons: 0
  }
  const stats = delivery.deliveryDetails.reduce((total, dd) => {
    total.totalProcessed += 1
    if (dd.notFilledReason) {
      total.totalNotFilled += 1
    }
    total.totalGallons += dd.fuelDispensed || 0
    return total
  }, initialStats)
  const now = moment()
  stats.elapsed = now.diff(moment(delivery.checkedInAt), 'minutes')
  stats.gph = round((stats.totalGallons / stats.elapsed) * 60, 1)
  return stats
}

SituationalAwarenessStats.propTypes = {
  fleetDelivery: propTypes.object,
  selectedTankerId: propTypes.string,
  fleetAccount: propTypes.object
}
export default SituationalAwarenessStats

import { DonutSegment } from './DonutSegment'
import React from 'react'
import colors from '../util/colors'

export const DonutChart = (props) => {
  const { properties } = props
  const offsets = []
  const counts = [properties.COMPLETE_WITHIN_15MIN, properties.COMPLETE_WITHIN_30MIN, properties.COMPLETE_WITHIN_1HR, properties.COMPLETE_WITHIN_2HR, properties.COMPLETE_BY_12PM, properties.COMPLETE_BY_END_OF_DAY]
  let total = 0
  for (let i = 0; i < counts.length; i++) {
    offsets.push(total)
    total += counts[i]
  }
  const fontSize = total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16
  const r = total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18
  const r0 = Math.round(r * 0.6)
  const w = r * 2

  const DonutChart = (
    <svg
      width={w}
      height={w}
      viewBox={`0 0 ${w} ${w}`}
      textAnchor='middle'
      style={{
        fontSize
      }}
    >
      {counts.map((count, i) => <DonutSegment key={i} start={offsets[i] / total} end={(offsets[i] + counts[i]) / total} r={r} r0={r0} color={colors[i]} />)}
      <circle
        cx={r}
        cy={r}
        r={r0}
        fill='white'
      />
      <text
        dominantBaseline='central'
        transform={`translate(${r}, ${r})`}
      >
        {Math.round(total, 0)}
      </text>
    </svg>
  )
  return DonutChart
}

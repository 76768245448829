export const tilesets = {
  noTraffic: {
    light: 'mapbox://styles/mapbox/light-v10',
    dark: 'mapbox://styles/mapbox/dark-v10'
  },
  traffic: {
    light: 'mapbox://styles/mapbox/navigation-preview-day-v4',
    dark: 'mapbox://styles/mapbox/navigation-preview-night-v4'
  },
  satellite: {
    light: 'mapbox://styles/mapbox/satellite-streets-v11',
    dark: 'mapbox://styles/mapbox/satellite-streets-v11'
  }
}

import moment from 'moment-timezone'

const initialState = {
  tankers: [],
  fuelTypeFilter: 'ALL',
  expandedTankers: false,
  selectedTanker: null
}

const oneDayAgo = moment().subtract(1, 'day').subtract(12, 'hours')

export default function (state = initialState, action) {
  if (action.error) return state

  switch (action.type) {
    case 'SET_TANKERS':
      const tankers = action.payload.map(t => {
        let isOptimusEnabled = false
        const { optimusLocationUpdatedAt } = t
        if (!optimusLocationUpdatedAt) {
          return {
            ...t,
            isOptimusEnabled
          }
        }
        if (moment(optimusLocationUpdatedAt).isAfter(oneDayAgo)) {
          isOptimusEnabled = true
        }
        return {
          ...t,
          isOptimusEnabled
        }
      }) || []
      return {
        ...state,
        tankers
      }

    case 'TANKER_SET_FUEL_TYPE_FILTER':
      return {
        ...state,
        fuelTypeFilter: action.payload
      }

    case 'TOGGLE_TANKER_EXPANSION':
      return {
        ...state,
        expandedTankers: !state.expandedTankers
      }

    case 'SELECT_TANKER':
      return {
        ...state,
        selectedTanker: action.payload
      }
    default:
      return state
  }
}

import React, { useState } from 'react'
import { MapContext, Marker } from 'react-mapbox-gl'
import { DonutChart } from '../DonutChart'
import { debounce } from 'lodash'
import styled from 'styled-components'

// Why is 'pointer-events: none' necessary?
// There's an issue where Markers are rendered on top of the DOM instead of the canvas, causing scrolling issues
// https://github.com/alex3165/react-mapbox-gl/issues/217
const MarkerWithNoPointerEvents = styled(Marker)`
  pointer-events: none;
`

export const FuelRequestCluster = (props) => {
  const [features, setFeatures] = useState([])
  return (
    <>
      <MapContext.Consumer>
        {(map) => {
          map.on('data', function (e) {
            if (e.sourceId !== 'fuelRequests') { return }
            map.on('move', () => debouncedUpdateFeatures(map, setFeatures))
            map.on('moveend', () => debouncedUpdateFeatures(map, setFeatures))
            debouncedUpdateFeatures(map, setFeatures)
          })
        }}
      </MapContext.Consumer>
      <>
        {features && features.map((f, i) => {
          const coords = f.geometry.coordinates
          const props = f.properties
          if (!props.cluster) { return null }
          return (
            <MarkerWithNoPointerEvents key={i} coordinates={coords}>
              <DonutChart properties={props} />
            </MarkerWithNoPointerEvents>
          )
        })}
      </>
    </>
  )
}

const debouncedUpdateFeatures = debounce(updateFeatures, 250)

function updateFeatures (map, featureSetter) {
  const features = map.querySourceFeatures('fuelRequests')
  featureSetter(features)
}

const initialState = {}

export default function (state = initialState, action) {
  if (action.error) return state

  switch (action.type) {
    case 'FETCH_DRIVERS_FULFILLED':
      return action.payload.reduce((drivers, driver) => {
        drivers[driver._id] = driver
        return drivers
      }, {}) || {}

    default:
      return state
  }
}

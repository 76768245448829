import React, { useState } from 'react'
import { Layer } from 'react-mapbox-gl'
import { FleetDeliveryClusterPopup } from '../MapboxPopups'

const FleetDeliveryCluster = (props) => {
  const [clusterId, setClusterId] = useState(null)
  const [coordinates, setCoordinates] = useState(null)
  const handleClick = (e) => {
    setClusterId(e.features[0].properties.cluster_id)
    setCoordinates(e.features[0].geometry.coordinates)
  }

  return (
    <>
      <Layer
        id='clustered-fds'
        onClick={handleClick}
        sourceId='fleetDeliveries'
        type='symbol'
        filter={['has', 'point_count']}
        layout={{
          'icon-image': 'fd-cluster-agg',
          'icon-size': 0.675,
          'icon-ignore-placement': true,
          'text-ignore-placement': true,
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
          'text-size': 10,
          'text-field': [
            'format',
            ['get', 'dieselCount'], { 'font-scale': 1.05 },
            '            ',
            ['get', 'unleadedCount'], { 'font-scale': 1.05 },
            '\n',
            'D: ',
            ['number-format', ['get', 'totalDsl'], { 'min-fraction-digits': 1, 'max-fraction-digits': 1 }],
            { 'font-scale': 0.90 },
            '\n',
            'R: ',
            ['number-format', ['get', 'totalReg'], { 'min-fraction-digits': 1, 'max-fraction-digits': 1 }],
            { 'font-scale': 0.90 },
            '\n',
            'P: ',
            ['number-format', ['get', 'totalPre'], { 'min-fraction-digits': 1, 'max-fraction-digits': 1 }],
            { 'font-scale': 0.90 }
          ],
          'text-anchor': 'bottom'
        }}
        paint={{
          'text-translate': [0, 24],
          'icon-opacity': 0.6
        }}
      />
      {clusterId && coordinates &&
        <FleetDeliveryClusterPopup
          clusterId={clusterId}
          coordinates={coordinates}
        />}
    </>
  )
}

export default FleetDeliveryCluster

import truncate from '@turf/truncate'
import { lineString } from '@turf/helpers'

export const createFeatureCollection = (payloadWithLocations) => {
  const features = payloadWithLocations.map(element => {
    let { location, lastLocation, lastModifiedLocation, ...properties } = element
    // This logic is to handle Tankers beacuse they don't have a location;
    // rather, they have a lastLocation
    if (!location && !lastLocation && !lastModifiedLocation) {
      return {
        type: 'FeatureCollection',
        features: []
      }
    }
    if (lastLocation) {
      location = lastLocation
    }
    if (lastModifiedLocation) {
      location = lastModifiedLocation
    }
    location.properties = {
      ...location.properties,
      ...properties
    }
    return truncateLocationCoords(location)
  })
  return {
    type: 'FeatureCollection',
    features
  }
}

export const convertFeatureCollectionToLineString = (featureCollection) => {
  const coordinates = featureCollection.features.map(f => f.geometry.coordinates)
  return lineString(coordinates)
}

function truncateLocationCoords (coords) {
  return truncate(coords, {
    precision: 8,
    coordinates: 2,
    mutate: true
  })
}

import React from 'react'

const FUEL_REQUEST_URL = 'https://tools.boosterfuels.com/#/fuel-requests'

export const FuelRequestPopup = (props) => {
  const { fuelRequest } = props
  const {
    _id,
    fuelType,
    status,
    deliveryWindow,
    estimatedFuelAmount
  } = fuelRequest.properties

  return (
    <div>
      <h1>{status}</h1>
      <h2>Estimated Gals: {Math.round(estimatedFuelAmount)}</h2>
      <h3>Complete by: {JSON.parse(deliveryWindow).end}</h3>
      <h3>{JSON.parse(deliveryWindow).id}</h3>
      <a
        href={`${FUEL_REQUEST_URL}/${_id}`}
        target='_blank'
        rel='noopener noreferrer'
      >BOA Link</a>
      <br />
      {fuelType}
    </div>
  )
}

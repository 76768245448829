import { Source } from 'react-mapbox-gl'
import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createFeatureCollection } from '../../util/geojsonUtils'
import actions from '../../actions'

class PolygonSources extends Component {
  componentDidMount () {
    this.props.fetchFleetPolygons({
      query: {
        disabled: false,
        location: {
          $exists: true
        }
      },
      project: {
        location: 1,
        navigationLocation: 1,
        name: 1,
        avgDieselGallonsLast5Deliveries: 1,
        avgPremiumGallonsLast5Deliveries: 1,
        avgRegularGallonsLast5Deliveries: 1,
        regionId: 1
      },
      limit: 0
    })
    this.props.fetchTerminalLocations({
      query: {
        location: {
          $exists: true
        }
      },
      project: {
        name: 1,
        location: 1,
        regionId: 1
      },
      limit: 0
    })
    this.props.fetchServiceLocationLocations({
      query: {
        location: {
          $exists: true
        },
        enabled: true
      },
      project: {
        name: 1,
        location: 1,
        regionId: 1
      },
      limit: 0
    })
    this.props.fetchTerritoryLocations({
      query: {
        location: {
          $exists: true
        }
      },
      project: {
        name: 1,
        location: 1,
        regionId: 1
      },
      limit: 0
    })
  }

  render () {
    const fleetAccounts = []
    for (let fa in this.props.fleetAccountLocations) {
      fleetAccounts.push(this.props.fleetAccountLocations[fa])
    }
    const fleetLocationGeoJSON = createFeatureCollection(fleetAccounts)
    const terminalLocationGeoJSON = createFeatureCollection(this.props.terminalLocations)
    const serviceLocationLocationGeoJSON = createFeatureCollection(this.props.serviceLocationLocations)
    const territoryLocationGeoJSON = createFeatureCollection(this.props.territoryLocations)

    return (
      <div>
        <Source id='fleetLocations'
          geoJsonSource={{
            type: 'geojson',
            data: fleetLocationGeoJSON
          }}
        />
        <Source id='terminalLocations'
          geoJsonSource={{
            type: 'geojson',
            data: terminalLocationGeoJSON
          }}
        />
        <Source id='consumerLocations'
          geoJsonSource={{
            type: 'geojson',
            data: serviceLocationLocationGeoJSON
          }}
        />
        <Source id='territoryLocations'
          geoJsonSource={{
            type: 'geojson',
            data: territoryLocationGeoJSON
          }}
        />
      </div>
    )
  }
}

const enhance = compose(
  connect(
    state => ({
      fleetAccountLocations: state.fleetAccount || [],
      terminalLocations: state.terminal || [],
      serviceLocationLocations: state.serviceLocation || [],
      territoryLocations: state.territory || []
    }),
    {
      fetchFleetPolygons: actions.fleetAccount.fetchPolygons,
      fetchTerminalLocations: actions.terminal.fetchLocations,
      fetchServiceLocationLocations: actions.serviceLocation.fetchLocations,
      fetchTerritoryLocations: actions.territory.fetchLocations
    }
  )
)

export default enhance(PolygonSources)

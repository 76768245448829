import * as api from '../util/api'

export function setPaginationOptions (payload) {
  return {
    type: 'SET_PAGINATION_OPTIONS',
    payload
  }
}

export function setFilterOptions (payload) {
  return {
    type: 'SET_FILTER_OPTIONS',
    payload
  }
}

export function fetchLocations (params) {
  return {
    type: 'FETCH_SL_LOCATIONS',
    payload: api.fetchServiceLocationLocations(params)
  }
}

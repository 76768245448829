import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import './index.css'
import App from './App'
import configureStore from './store'

const { store, persistor } = configureStore()

const muiTheme = createMuiTheme({
  fontFamily: 'Lato',
  typography: {
    fontFamily: [
      'Lato'
    ].join(',')
  },
  palette: {
    primary: {
      main: '#60008D'
    },
    secondary: {
      main: '#8627A8'
    },
    tertiary: {
      main: '#8F8F9A'
    },
    accent: {
      main: '#FF6581'
    },
    text: {
      main: '#504E53'
    }
  },
  overrides: {
    MuiMenuItem: {
      root: {
        '&$selected': {
          backgroundColor: '#60008D',
          color: 'white'
        }
      }
    }
  }
})

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MuiThemeProvider theme={muiTheme}>
        <App />
      </MuiThemeProvider>
    </PersistGate>
  </Provider>
  , document.getElementById('root'))

import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import actions from '../actions'
import styled from 'styled-components'
import { TilesetControlContainer } from './TilesetControl'
import {
  Button,
  Checkbox,
  Grid,
  Drawer,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Container
} from '@material-ui/core'

import {
  SettingsApplications
} from '@material-ui/icons'
import {
  FuelRequestControl,
  FleetDeliveryControl,
  TankerControl
} from './LayerMapControls'
const TopRightDiv = styled.div`
  && { position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  }
`

class LayerControl extends Component {
  constructor (props) {
    super(props)
    const { children } = this.props
    this.handleOpenMenu = this.handleOpenMenu.bind(this)
    this.handleCloseMenu = this.handleCloseMenu.bind(this)

    const state = { settings: {} }
    children.forEach(child => {
      state.settings[child.props.layerName] = false
    })
    this.state = {
      isOpenDrawer: false,
      tabIndex: 0,
      ...state
    }
  }

  componentDidMount () {
    this.props.getUserSettings()
  }

  componentDidUpdate (prevProps) {
    if (this.props.customer.userSettings !== prevProps.customer.userSettings) {
      if (this.props.customer.userSettings) {
        this.setState({
          settings: {
            ...this.state.settings,
            ...this.props.customer.userSettings
          }
        }, () => {
          if (this.noneIsSelected()) {
            this.setState({
              isOpenDrawer: true
            })
          }
        })
      }
    }
  }

  noneIsSelected () {
    const settings = Object.keys(this.state.settings).map(key => this.state.settings[key])
    if (settings.every(s => s === false)) {
      return true
    }
    return false
  }

  toggleLayer (key, e) {
    const oldState = this.state.settings[key]
    this.setState({
      settings: {
        ...this.state.settings,
        [key]: !oldState
      }
    })
  }

  handleOpenMenu () {
    this.setState({
      isOpenDrawer: true
    })
  }

  handleCloseMenu () {
    this.setState({
      isOpenDrawer: false
    })
    this.props.setUserSettings(this.state.settings)
  }

  handleTabChange (idx) {
    this.setState({
      tabIndex: idx
    })
  }

  render () {
    const { children } = this.props
    const { isOpenDrawer, tabIndex } = this.state
    const { userSettings } = this.props.customer
    return (
      <>
        <TopRightDiv>
          {userSettings && (userSettings['Fuel Requests'] || userSettings['SP claimed pins']) && <FuelRequestControl />}
          {userSettings && userSettings['Fleet Deliveries'] && <FleetDeliveryControl />}
          {userSettings && userSettings['Tanker'] && <TankerControl />}
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleOpenMenu}
          >
            <SettingsApplications />
          </Button>
        </TopRightDiv>
        <Drawer
          anchor='right'
          open={isOpenDrawer}
          onClose={this.handleCloseMenu}
        >
          <AppBar position='static'>
            <Tabs value={tabIndex} onChange={(e, idx) => this.handleTabChange(idx)}>
              <Tab label='Map Layers' />
              <Tab label='Map Tileset' />
            </Tabs>
          </AppBar>
          <Container>
            <Grid
              container
              direction='column'
              wrap='wrap'
            >
              {tabIndex === 0 && children.map(c => {
                const { layerName } = c.props
                const toggleLayer = this.toggleLayer.bind(this, layerName)
                return (
                  <Grid item
                    key={layerName}
                  >
                    <Typography>
                      <Checkbox
                        key={layerName}
                        onChange={toggleLayer}
                        checked={this.state.settings[layerName]}
                      />
                      {layerName}
                    </Typography>
                  </Grid>
                )
              })}
              {tabIndex === 1 &&
                <TilesetControlContainer />}
              <Button
                color='primary'
                variant='contained'
                onClick={this.handleCloseMenu}
              >
                {this.noneIsSelected() ? 'Select a layer' : 'Done'}
              </Button>
            </Grid>
          </Container>
        </Drawer>
        {children.map(c => {
          if (this.state.settings[c.props.layerName]) {
            return c
          }
          return null
        })}
      </>
    )
  }
}

const enhance = compose(
  connect(
    state => ({
      customer: state.customer
    }),
    {
      getUserSettings: actions.customer.getUserSettings,
      setUserSettings: actions.customer.setUserSettings
    }
  )
)

export default enhance(LayerControl)

const initialState = []

export default function (state = initialState, action) {
  if (action.error) return state

  switch (action.type) {
    case 'FETCH_SL_LOCATIONS_FULFILLED':
      return action.payload || []

    default:
      return state
  }
}

import { Source } from "react-mapbox-gl";
import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { createFeatureCollection } from "../../util/geojsonUtils";
import actions from "../../actions";
import { isFilteredFuelType } from "../../util/isFilteredFuelType";
import { throttle } from "lodash";

import firebase from "../../util/firebase";

import { v0TankerLiveUpdate, canSee } from "../../util/featureGuard";

class TankerSource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unsubscribeFromCurrentTankerSnapshot: null,
      unsubscribeFromCurrentTankSnapshot: null,
    };
  }

  componentDidMount() {
    const { selectedRegion } = this.props.regions;
    if (selectedRegion && this.props.customer.firebaseAuthenticated) {
      this.props.fetchDrivers({
        query: { regionId: selectedRegion._id },
      });
      this.subscribeToTankerAndTankFromFirebase();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.regions.selectedRegion !== prevProps.regions.selectedRegion &&
      this.props.customer.firebaseAuthenticated
    ) {
      this.unsubscribeFromCurrentTankerAndTankSnapshot();
      this.subscribeToTankerAndTankFromFirebase();
    }
  }

  render() {
    const { drivers } = this.props;
    const { fuelTypeFilter } = this.props.tankers;
    const tankersWithTanks = this.props.tankers.tankers.map((tanker) => {
      return {
        ...tanker,
        tanks: this.props.tanks.filter((t) => t.tankerId === tanker._id),
        driver: drivers[tanker.currentDriverId],
      };
    });
    const fuelTypeFilteredTankers = tankersWithTanks.filter((t) =>
      isFilteredFuelType(determineFuelTypes(t), fuelTypeFilter)
    );
    const tankerLocationGeoJSON = createFeatureCollection(
      fuelTypeFilteredTankers
    );
    return (
      <Source
        id="tankerLocations"
        geoJsonSource={{
          type: "geojson",
          data: tankerLocationGeoJSON,
        }}
      />
    );
  }

  unsubscribeFromCurrentTankerAndTankSnapshot() {
    if (canSee(v0TankerLiveUpdate, this.props.customer)) {
      const {
        unsubscribeFromCurrentTankerSnapshot,
        unsubscribeFromCurrentTankSnapshot,
      } = this.state;
      if (
        unsubscribeFromCurrentTankerSnapshot &&
        unsubscribeFromCurrentTankSnapshot
      ) {
        unsubscribeFromCurrentTankSnapshot();
        unsubscribeFromCurrentTankerSnapshot();
      }
    }
  }

  subscribeToTankerAndTankFromFirebase() {
    if (canSee(v0TankerLiveUpdate, this.props.customer)) {
      this.subscribeToTankerFromFirebase();
      this.subscribeToTankFromFirebase();
    }
  }

  subscribeToTankFromFirebase() {
    const tankSnapshotUnsubscriber = firebase
      .firestore()
      .collection("Tank")
      .onSnapshot((data) => {
        this.props.setTanks(data.docs.map((doc) => doc.data()));
      });
    this.setState({
      unsubscribeFromCurrentTankSnapshot: tankSnapshotUnsubscriber,
    });
  }

  subscribeToTankerFromFirebase() {
    const TankerSnapshotUnsubscriber = firebase
      .firestore()
      .collection("Tanker")
      .where("status", "==", "ON_TRIP")
      .where("regionId", "==", this.props.regions.selectedRegion._id)
      .onSnapshot((data) => {
        this.props.setTankers(
          data.docs.map((doc) => doc.data()).filter((doc) => doc.lastLocation)
        );
      });
    this.setState({
      unsubscribeFromCurrentTankerSnapshot: TankerSnapshotUnsubscriber,
    });
  }
}

const determineFuelTypes = (t) => {
  const fuelTypes = t.tanks.map((tank) => {
    if (tank.currentFuelType === "DIESEL") {
      return "DIESEL";
    }
    if (tank.currentFuelType === "PREMIUM") {
      return "PREMIUM";
    }
    if (tank.currentFuelType === "REGULAR") {
      return "REGULAR";
    }
    return "";
  });
  return fuelTypes;
};

const enhance = compose(
  connect(
    (state) => ({
      tankers: state.tankers || [],
      tanks: state.tank || [],
      drivers: state.drivers || {},
      customer: state.customer,
      regions: state.regions,
    }),
    {
      setTankers: throttle(actions.map.setTankers, 1000),
      setTanks: throttle(actions.tank.setTanks, 1000),
      fetchDrivers: actions.drivers.fetch,
    }
  )
);

export default enhance(TankerSource);

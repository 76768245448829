import React, { useState, Fragment } from 'react'
import { Layer } from 'react-mapbox-gl'
import { HoverOverTextLayer } from '.'

export const TerritoryPolygonLayer = (props) => {
  const [ territory, setTerritory ] = useState(null)
  const [ selectedCoordinates, setCoordinates ] = useState(null)
  return (
    <Fragment>
      <Layer
        id='territory-polygons'
        type='fill'
        sourceId='territoryLocations'
        paint={{
          'fill-color': '#FFEB3B',
          'fill-opacity': 0.25,
          'fill-outline-color': '#9B870C'
        }}
        onMouseEnter={(e) => {
          const { lng, lat } = e.lngLat
          setCoordinates([lng, lat])
          setTerritory(e.features[0].properties)
        }}
        onMouseLeave={e => {
          setTerritory(null)
          setCoordinates(null)
        }}
      />
      <HoverOverTextLayer
        coordinates={selectedCoordinates}
        properties={territory}
      />
    </Fragment>
  )
}

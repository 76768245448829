const initialState = {
  argosToken: null,
  firebaseAuthenticated: false
}

export default function (state = initialState, action) {
  if (action.error) return state
  switch (action.type) {
    case 'LOAD_USER_FULFILLED':
      return Object.assign({}, state, action.payload.customer)
    case 'GENERATE_ARGOS_TOKEN_FULFILLED':
      return {
        ...state,
        argosToken: action.payload.argosToken
      }

    case 'FIREBASE_AUTHENTICATED':
      return {
        ...state,
        firebaseAuthenticated: true
      }
    case 'LOGIN_FULFILLED':
      return {
        ...state,
        ...action.payload
      }

    case 'LOAD_SETTINGS_FULFILLED':
      return {
        ...state,
        userSettings: action.payload
      }

    case 'SET_SETTINGS_FULFILLED':
      return {
        ...state,
        userSettings: action.payload
      }

    case 'LOGOUT_FULFILLED':
      return initialState

    default:
      return state
  }
}

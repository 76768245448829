import React, { useState, Fragment } from 'react'
import { Layer } from 'react-mapbox-gl'
import { HoverOverTextLayer } from '.'

export const ServiceLocationPolygonLayer = (props) => {
  const [ serviceLocation, setServiceLocation ] = useState(null)
  const [ selectedCoordinates, setCoordinates ] = useState(null)
  return (
    <Fragment>
      <Layer
        id='sl-polygons'
        type='fill'
        sourceId='consumerLocations'
        paint={{
          'fill-color': '#00FF00',
          'fill-opacity': 0.25,
          'fill-outline-color': '#006400'
        }}
        onMouseEnter={(e) => {
          const { lng, lat } = e.lngLat
          setCoordinates([lng, lat])
          setServiceLocation(e.features[0].properties)
        }}
        onMouseLeave={e => {
          setServiceLocation(null)
          setCoordinates(null)
        }}
      />
      <HoverOverTextLayer
        coordinates={selectedCoordinates}
        properties={serviceLocation}
      />
    </Fragment>
  )
}

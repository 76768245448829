export function clearError () {
  return {
    type: 'CLEAR_ERROR'
  }
}

export function loginError () {
  return {
    type: 'LOGIN_FAILED'
  }
}

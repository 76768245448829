import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { Search, ChevronLeft } from '@material-ui/icons'
import { FleetAndConsumerSearch, TankerAndSPSearch } from '.'
import styled from 'styled-components'

const FlexDiv = styled.div`
  display: flex;
  flex: 1;
`

export const SearchContainer = (props) => {
  const [ isExpanded, setIsExpanded ] = useState(false)

  return (
    <div>
      {
        isExpanded
          ? <FlexDiv>
            <TankerAndSPSearch />
            <FleetAndConsumerSearch />
            <Button
              color='secondary'
              variant='outlined'
              size='small'
              onClick={() => setIsExpanded(false)}
            >
              <ChevronLeft />
            </Button>
          </FlexDiv>
          : <Button
            color='primary'
            variant='contained'
            size='small'
            onClick={() => setIsExpanded(true)}
          >
            <Search />
          </Button>
      }
    </div>
  )
}

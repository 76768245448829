const initialState = {
  errorMessage: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'API_ERROR':
    case 'GLOBAL_ERROR':
      return Object.assign({}, state, {
        errorMessage: action.error.message
      })

    case 'LOGIN_FAILED':
      return {
        ...state,
        errorMessage: 'Login failed. Email or password is incorrect.'
      }

    case 'CLEAR_ERROR':
      return Object.assign({}, state, {
        errorMessage: null
      })

    default:
      return state
  }
}

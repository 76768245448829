import * as api from '../util/api'
import { StorageFactory } from '../util/localStorage'

const localStore = StorageFactory(() => window.localStorage)

export function login ({ email, password }) {
  return {
    type: 'LOGIN',
    payload: api.login({ email, password })
  }
}

export function logout () {
  return {
    type: 'LOGOUT',
    payload: api.logout()
  }
}

export function getCustomerState () {
  return {
    type: 'LOAD_USER',
    payload: api.getCustomerState()
  }
}

export function generateArgosToken () {
  return {
    type: 'GENERATE_ARGOS_TOKEN',
    payload: api.generateArgosToken()
  }
}

export function setFirebaseAuthenticated (isAuthed) {
  return {
    type: 'FIREBASE_AUTHENTICATED',
    payload: isAuthed
  }
}

async function loadSettingsFromLocalStorage () {
  if (!localStore) { return }
  let userSettings = localStore.getItem('argosSettings')
  if (userSettings) {
    userSettings = JSON.parse(userSettings)
  }
  return userSettings || []
}

export function getUserSettings () {
  return {
    type: 'LOAD_SETTINGS',
    payload: loadSettingsFromLocalStorage()
  }
}

async function setSettingsInLocalStorage (settings) {
  if (!localStore) { return }
  localStore.setItem('argosSettings', JSON.stringify(settings))
  return settings
}

export function setUserSettings (settings) {
  return {
    type: 'SET_SETTINGS',
    payload: setSettingsInLocalStorage(settings)
  }
}
